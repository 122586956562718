import React, { useState } from "react";

//Material UI Components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

//Dialog Components
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";

export const CsvDownload = (props) => {
  var oData = {};
  if (props.data) {
    oData = props.data;
  }

  function handleChange(oEvent){
    oData.tableValue = oEvent.target.value;
    props.updatedata(oData)
  }

  /**
   * Event handler for update
   * @param {*} oEvent
   */
  function handleUpdate() {
    props.update();
  }

  return (
    <div>
      <Dialog open={props.open} maxWidth="sm" fullWidth={true}>
        <DialogTitle>Map Data</DialogTitle>
        <DialogContent>
          <FormGroup>
            {" "}
            <TextField
              select
              id="csvdownload"
              label="CSV"
              sx={{ minWidth: 450 }}
              onChange={(oEvent) => {
                handleChange(oEvent);
              }}
              defaultValue={oData && oData.tableValue ? oData.tableValue : ""}
            >
              {props.inputs && props.inputs.map((oField) => (
                <MenuItem key={oField} name={oField} value={oField}>
                  {oField}
                </MenuItem>
              ))}
            </TextField>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdate}>Update</Button>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
