import React, { useState, useEffect } from "react";
import "./MenuComponents.css";
import Server from "../../../../serverDetails/server";
import fetchTableListWithFields from "../../../../dialog/logics/dbOperations/fetchDataBaseList/fetchDataBaseList";

//Icon
import Add_Icon from "./images/Add.png";
import PublishRoundedIcon from "@mui/icons-material/PublishRounded";

//Dialog
import { CreatePageDetail } from "../../../../dialog/leftPanel/createPage/CreatePageDialog";
import { CreateFormDialog } from "../../../../dialog/leftPanel/createForm/CreateFormDialog";
import { PageConfirm } from "../../../../dialog/leftPanel/confirmDialog/PageConfirmDialog";
import { FormConfirm } from "../../../../dialog/leftPanel/confirmDialog/FormConfirmDialog";
import { DeleteCollection } from "../../../../dialog/logics/collection/DeleteCollection";
import { CreateDB } from "../../../../dialog/logics/dbOperations/createDB/CreateDB";
import { CreateApi } from "../../../../dialog/leftPanel/api/CreateApi";
import { CreateRoute } from "../../../../dialog/leftPanel/api/CreateRoute";
import { ApiAuthentication } from "../../../../dialog/leftPanel/security/ApiAuthentication";
import { ApiPublish } from "../../../../dialog/toolBar/apiPublishDialog/ApiPublish";

//Material ui
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export const MenuComponents = (props) => {
  const [open, openCreatePageDialog] = useState(false);
  const [sNewPageID, setNewPageID] = useState("");
  const [bformOpen, setformOpen] = useState(false);
  const [sNewFormID, setNewFormID] = useState("");
  const [bPageDelete, setPageDelete] = useState(false);
  const [bFormDelete, setFormDelete] = useState(false);
  const [oSelectedPage, setSelectedPage] = useState({});
  const [oSelectedForm, setSelectedForm] = useState({});
  const [aDataBaselist, setDataBaselist] = useState([]);
  const [bCollectionDeleteDialog, setCollectionDeleteDialog] = useState(false);
  const [sCollectionName, setCollectionName] = useState();
  const [bCreateDB, setCreateDB] = useState(false);
  const [oDataFields, setDataFields] = useState("");

  //API STATES
  const [aApiList, setApiList] = useState([]);
  const [sSelectedApi, setSelectedApi] = useState("");
  const [bCreateApi, setCreateApi] = useState(false);
  const [bCreateRoute, setCreateRoute] = useState(false);
  const [sSelectedPath, setSelectedPath] = useState();
  const [bGeo_API, setGeo_API]  = useState(false);

  //Api Auth States
  const [bAuthDialog, setAuthDialog] = useState(false);
  const [aAuthData, setAuthData] = useState({});

  //Api PublishDialog
  const [bApiPublish, setApiPublish] = useState(false);

  var aMenuComponentsTitle = {
    designer: "UI Components",
    form: "UI Components",
    workflow: "Logic",
    validation: "ValidationLogic",
    data: "Database",
    api: "API",
  };

  var aComponentList = "";
  var aPageList = [];
  var aformList = [];
  handleSelectedItem();

  async function handleApiList() {
    var aApiData = [];
    await Server.get("/editor/api", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    }).then(function (response) {
      aApiData = response.data.Data;
    });
    return aApiData;
  }

  async function handleDBApiCreate(apiid, description, routedetail, geoapi, featid) {
    var aApiData = {
      API_ID: apiid,
      API_DESCRIPTION: description,
      GEO_API: geoapi,
      APIDATA: routedetail,
      ...(localStorage.getItem('CID') === "SYSTEM" && { FEAT_ID: featid })
    };
    try {
      await Server.post("/editor/api", aApiData, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const aApi = await handleApiList();
      setApiList(aApi);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleDBApiAuth(apiid, aRowData) {
    var aApiData = {
      API_ID: apiid,
      Auth_Data: aRowData,
    };
    try {
      await Server.post("/editor/api", aApiData, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const aApi = await handleApiList();
      setApiList(aApi);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleDBApiDelete(apiid, Path) {
    var oApiData = {
      API_ID: apiid,
      API_Path: Path,
    };
    try {
      await Server.delete("/editor/api", {
        data: oApiData,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const aApi = await handleApiList();
      setApiList(aApi);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleSelectedItem() {
    switch (props.selectedItem) {
      case "designer":
        aComponentList = require("../../../../model/designer.json");
        break;
      case "form":
        aComponentList = require("../../../../model/form.json");
        break;
      case "workflow":
        aComponentList = require("../../../../model/workflow.json");
        break;
      case "validation":
        aComponentList = require("../../../../model/validation.json");
        break;
      case "data":
        const aDBList = await fetchTableListWithFields();
        if (aDBList.length !== aDataBaselist.length) {
          setDataBaselist(aDBList);
        }
        break;
      case "api":
        const aApi = await handleApiList();
        if (aApiList.length !== aApi.length) {
          setApiList(aApi);
        }
        break;
      default:
        aComponentList = require("../../../../model/designer.json");
        break;
    }
  }

  for (const page in props.pages) {
    if (page === "forms") {
      const forms = props.pages[page];
      for (const form in forms) {
        aformList.push(forms[form]);
      }
    } else {
      aPageList.push(props.pages[page]);
    }
  }

  /**
   * Event handler during drag and drop of component
   * @param {event} oEvent | Event
   * @param {JSON} oComponentModel | Json data of the component
   */
  function handleDragStart(oEvent, oComponentModel, bData) {
    if (bData) {
      oEvent.dataTransfer.setData("json", JSON.stringify(oComponentModel));
    } else {
      oEvent.dataTransfer.setData(
        "json",
        JSON.stringify(oComponentModel.oComponent)
      );
    }
  }

  // API FUNCTIONS

  function handleApiClick(event, id, geo) {
    setSelectedApi(id);
    if(geo){
      setGeo_API(true);
    }
  }

  function handleRouteClick(event, id) {
    setSelectedPath(id);
    setCreateRoute(true);
  }

  function handleCreateApi() {
    setCreateApi(true);
  }

  async function handleNewApi(description,bGeoAPI,FeatId) {
    await handleDBApiCreate("", description, {},bGeoAPI,FeatId);
    handleCloseCreateApi();
  }

  async function handleUpdateRoute(oData) {
    var description = aApiList.find(
      (oApis) => oApis.API_ID === sSelectedApi
    ).API_DESCRIPTION;
    var geoapi = aApiList.find(
      (oApis) => oApis.API_ID === sSelectedApi
    )?.GEO_API ?? false;    
    await handleDBApiCreate(sSelectedApi, description, oData, geoapi);
    handleCloseCreateApi();
  }

  function handleCloseCreateApi() {
    setCreateApi(false);
    setCreateRoute(false);
  }

  function handleCreateRoute() {
    if (sSelectedApi) {
      setCreateRoute(true);
    } else {
      alert("Select Any API");
    }
  }

  async function handleDeleteApi(apiid) {
    var bConfirm = window.confirm("Do you want to Delete API?");
    if (bConfirm) {
      await handleDBApiDelete(apiid, false);
      const aApi = await handleApiList();
      setApiList(aApi);
    }
  }

  async function handleDeleteRoute(Path) {
    var bConfirm = window.confirm("Do you want to Delete Route?");
    if (bConfirm) {
      await handleDBApiDelete(sSelectedApi, Path);
    }
  }

  function handleAuthDialog(id) {
    setSelectedApi(id);
    var aData = aApiList.find((oApi) => oApi.API_ID === id)?.AuthObjects;
    if (aData && aData.length > 0) {
      setAuthData({ collections: aData });
    } else {
      setAuthData({});
    }
    setAuthDialog(true);
  }

  async function handleApiAuthUpdate(aRowData) {
    await handleDBApiAuth(sSelectedApi, aRowData.collections);
  }

  function handlePublishAPI() {
    setApiPublish(true);
  }
  /**
   * Add new page
   */
  function handleAddPage() {
    var sUniquePageID = generateUniquePageId(aPageList.length);
    openCreatePageDialog(true);
    setNewPageID(sUniquePageID);
  }

  /**
   * Generate unique page ID
   * @param {integer} index
   * @returns
   */
  function generateUniquePageId(index) {
    var isExist = false;
    var sNewValue = "";
    for (const page in props.pages) {
      if (props.pages[page].id === "page" + index) {
        isExist = true;
      }
    }
    if (isExist) {
      sNewValue = generateUniquePageId(index + 1);
      return sNewValue;
    } else {
      sNewValue = "page" + index;
      return sNewValue;
    }
  }

  /**
   * Create new page
   * @param {string} pageId
   * @param {string} pageName
   */
  function handleCreatePage(pageId, pageName) {
    var oPage = {
      id: pageId,
      name: pageName,
      UIComponents: [],
    };
    openCreatePageDialog(false);
    props.addPage(oPage);
  }

  /**
   * Cancel page creation
   */
  function handleCreatePageCancel() {
    openCreatePageDialog(false);
  }

  /**
   * Hnadle page click
   * @param {object} oPage
   */
  function handlePageClick(oPage) {
    props.selectPage(oPage);
  }

  /**
   * Delete page handler
   * @param {object} oPage
   */
  function handleDeletePage(oPage) {
    setPageDelete(true);
    setSelectedPage(oPage);
  }

  function handelDeletePageCancel() {
    setPageDelete(false);
  }

  /**
   * Add new Form
   */
  useEffect(() => {
    if (props.selectedItem === "form" && !props.pages.hasOwnProperty("forms")) {
      handelAddFrom();
    }
  }, [props.selectedItem, props.pages]);

  const handelAddFrom = () => {
    var sUniqueFormID = generateUniqueFormId(aformList.length + 1);
    setformOpen(true);
    setNewFormID(sUniqueFormID);
  };

  /**
   * Generate unique Form ID
   * @param {integer} index
   * @returns
   */
  function generateUniqueFormId(index) {
    var isExist = false;
    var sNewValue = "";
    for (const page in props.pages) {
      if (page === "forms") {
        const forms = props.pages[page];
        for (const form in forms) {
          if (forms[form].id === "form" + index) {
            isExist = true;
          }
        }
      }
    }
    if (isExist) {
      sNewValue = generateUniqueFormId(index + 1);
      return sNewValue;
    } else {
      sNewValue = "form" + index;
      return sNewValue;
    }
  }

  /**
   * Create new Form
   * @param {string} formId
   * @param {sring} formName
   */
  function handleCreateForm(formId, formName) {
    var oform = {
      id: formId,
      name: formName,
      UIComponents: [],
    };
    setformOpen(false);
    props.addForm(oform);
  }

  /**
   * Hnadle Form click
   * @param {object} oForm
   */
  function handleFormClick(oForm) {
    props.selectForm(oForm);
  }

  /**
   * Delete Form handler
   * @param {object} oForm
   */
  function handleDeleteForm(oForm) {
    setFormDelete(true);
    setSelectedForm(oForm);
  }

  const handleDeleteCollection = (oComponent) => {
    setCollectionName(oComponent.tableName);
    setCollectionDeleteDialog(true);
  };

  const handlecopyCollection = (oComponent) => {
    setCollectionName(oComponent.tableName);
    setCreateDB(true);
    setDataFields(oComponent.fields);
  };

  function handleDialogClose() {
    setCollectionDeleteDialog(false);
    setCreateDB(false);
    setFormDelete(false);
    setformOpen(false);
    setAuthDialog(false);
    setApiPublish(false);
  }

  function handleCreateDB() {
    setCreateDB(true);
  }

  return (
    <div className="MenuComponents-div">
      <div className="MenuComponents-Title" style={{ display: "flex" }}>
        <label className="MenuComponents-UIComponent-Label">
          {aMenuComponentsTitle[props.selectedItem]}
          {props.selectedItem === "api" ? (
            <>
              <IconButton
                style={{
                  fontSize: "14px",
                  color: "white",
                  marginBottom: "5px",
                  marginLeft: "20px",
                }}
                onClick={handleCreateApi}
              >
                <AddCircleIcon />
              </IconButton>
              <IconButton
                size="small"
                style={{
                  fontSize: "14px",
                  color: "white",
                  marginBottom: "5px",
                }}
                onClick={handlePublishAPI}
              >
                <PublishRoundedIcon />
              </IconButton>
            </>
          ) : null}
        </label>
      </div>
      <div className="MenuComponents-ComponentsList">
        <div className="scrollable-container">
          {props.selectedItem !== "data" && props.selectedItem !== "api" ? (
            <>
              {aComponentList.length > 0 &&
                aComponentList.map((oComponent, index) => (
                  <div
                    key={oComponent.id}
                    className="MenuComponents-UIComponentDiv"
                    draggable="true"
                    onDragStart={(event) =>
                      handleDragStart(event, { oComponent })
                    }
                  >
                    <div className="MenuComponents-UIComponentLable">
                      {oComponent.Component}
                    </div>
                  </div>
                ))}
              {aComponentList.message && <div>{aComponentList.message}</div>}
            </>
          ) : props.selectedItem === "data" ? (
            <>
              {aDataBaselist.length > 0 &&
                aDataBaselist.map((oComponent, index) => (
                  <div
                    key={index}
                    className="MenuComponentsdata-UIComponentDiv1"
                    draggable="true"
                    onDragStart={(event) =>
                      handleDragStart(event, { oComponent }, true)
                    }
                  >
                    <div className="MenuComponentsdata-UIComponentLable">
                      {oComponent.tableName}
                    </div>
                    <IconButton
                      style={{ fontSize: "8px", color: "white" }}
                      fontSize="small"
                      onClick={(oEvent) => {
                        handlecopyCollection(oComponent);
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                    <IconButton
                      style={{ fontSize: "8px" }}
                      fontSize="small"
                      onClick={(oEvent) => {
                        handleDeleteCollection(oComponent);
                      }}
                    >
                      <DeleteForeverRoundedIcon style={{ color: "white" }} />
                    </IconButton>
                  </div>
                ))}
            </>
          ) : props.selectedItem === "api" ? (
            <>
              {aApiList.length > 0 &&
                aApiList.map((oComponent, index) => (
                  <div
                    key={index}
                    className="MenuComponentsapi-UIComponentDiv1"
                    onClick={(event) =>
                      handleApiClick(event, oComponent.API_ID, oComponent.GEO_API)
                    }
                  >
                    <div className="MenuComponentsdata-UIComponentLable">
                      {oComponent.API_DESCRIPTION}
                    </div>
                    <IconButton
                      size="small"
                      onClick={(event) => handleDeleteApi(oComponent.API_ID)}
                    >
                      <DeleteForeverRoundedIcon style={{ color: "white" }} />
                    </IconButton>
                    <IconButton
                      size="10px"
                      style={{ marginLeft: "-10px" }}
                      onClick={(event) => handleAuthDialog(oComponent.API_ID)}
                    >
                      <SettingsIcon style={{ color: "white" }} />
                    </IconButton>
                  </div>
                ))}
            </>
          ) : null}
        </div>
      </div>
      {props.selectedItem !== "data" ? (
        props.selectedItem !== "form" ? (
          props.selectedItem !== "api" ? (
            <>
              <div className="MenuComponents-Title">
                <label className="MenuComponents-UIComponent-Label">
                  Pages
                </label>
              </div>
              <div className="MenuComponents-PageList">
                <div className="MenuComponents-PageDetails">
                  {aPageList.length > 0 &&
                    aPageList.map((oPage) => {
                      return (
                        <div key={oPage.id}>
                          <Stack direction="row" spacing={1}>
                            <label
                              className="MenuComponents-PageList"
                              onClick={(oEvent) => {
                                handlePageClick(oPage);
                              }}
                            >
                              {oPage.name}
                            </label>
                            <IconButton
                              size="small"
                              onClick={(oEvent) => {
                                handleDeletePage(oPage);
                              }}
                            >
                              <DeleteForeverRoundedIcon
                                style={{ color: "white" }}
                              />
                            </IconButton>
                          </Stack>
                        </div>
                      );
                    })}
                </div>
                <div className="MenuComponents-AddPage">
                  <button
                    className="MenuComponents-AddPageButton"
                    onClick={handleAddPage}
                  >
                    <img
                      src={Add_Icon}
                      alt="Add Icon"
                      width="50px"
                      height="50px"
                    />
                  </button>
                  <CreatePageDetail
                    open={open}
                    pageId={sNewPageID}
                    addPage={props.addPage}
                    submit={handleCreatePage}
                    close={handleCreatePageCancel}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="MenuComponents-Title">
                <label className="MenuComponents-UIComponent-Label">
                  Routes
                </label>
              </div>
              <div className="MenuComponents-PageList">
                <div className="MenuComponents-PageDetails">
                  {aApiList.length > 0 &&
                    sSelectedApi &&
                    aApiList.map((oApi) => {
                      if (oApi.API_ID === sSelectedApi) {
                        return oApi.RouteDetail.map((oRoute) => (
                          <div
                            key={oRoute.Path}
                            onClick={(event) =>
                              handleRouteClick(event, oRoute.Path)
                            }
                          >
                            <Stack direction="row" spacing={2}>
                              <label className="MenuComponentsapi-UIComponentLable">
                                {oRoute.Path}
                              </label>
                              <IconButton
                                size="small"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleDeleteRoute(oRoute.Path);
                                }}
                              >
                                <DeleteForeverRoundedIcon />
                              </IconButton>
                            </Stack>
                          </div>
                        ));
                      } else {
                        return null;
                      }
                    })}
                </div>
                <div className="MenuComponents-AddPage">
                  <button
                    className="MenuComponents-AddPageButton"
                    onClick={handleCreateRoute}
                  >
                    <img
                      src={Add_Icon}
                      alt="Add Icon"
                      width="50px"
                      height="50px"
                    />
                  </button>
                </div>
              </div>
            </>
          )
        ) : (
          <>
            <div className="MenuComponents-Title">
              <label className="MenuComponents-UIComponent-Label">Forms</label>
            </div>
            <div className="MenuComponents-PageList">
              <div className="MenuComponents-PageDetails">
                {aformList.length > 0 &&
                  aformList.map((oForm) => {
                    return (
                      <div key={oForm.id}>
                        <Stack direction="row" spacing={1}>
                          <label
                            className="MenuComponents-PageList"
                            onClick={(oEvent) => {
                              handleFormClick(oForm);
                            }}
                          >
                            {oForm.name}
                          </label>
                          <IconButton
                            size="small"
                            onClick={(oEvent) => {
                              handleDeleteForm(oForm);
                            }}
                          >
                            <DeleteForeverRoundedIcon />
                          </IconButton>
                        </Stack>
                      </div>
                    );
                  })}
              </div>
              <div className="MenuComponents-AddPage">
                <button
                  className="MenuComponents-AddPageButton"
                  onClick={handelAddFrom}
                >
                  <img
                    src={Add_Icon}
                    alt="Add Icon"
                    width="50px"
                    height="50px"
                  />
                </button>
                <CreateFormDialog
                  open={bformOpen}
                  formId={sNewFormID}
                  addForm={props.addForm}
                  submit={handleCreateForm}
                  close={handleDialogClose}
                />
              </div>
            </div>
          </>
        )
      ) : (
        <>
          <div className="MenuComponents-Title">
            <label className="MenuComponents-UIComponent-Label">
              Databases
            </label>
          </div>
          <div style={{ height: "32%" }}></div>
          <div className="MenuComponents-AddPage" style={{ height: "15%" }}>
            <button
              className="MenuComponents-AddPageButton"
              onClick={handleCreateDB}
            >
              <img src={Add_Icon} alt="Add Icon" width="50px" height="50px" />
            </button>
            <CreateFormDialog
              open={bformOpen}
              formId={sNewFormID}
              addForm={props.addForm}
              submit={handleCreateForm}
              close={handleDialogClose}
            />
          </div>
        </>
      )}
      <PageConfirm
        open={bPageDelete}
        page={oSelectedPage}
        delete={props.deletePage}
        cancel={handelDeletePageCancel}
      />
      <FormConfirm
        open={bFormDelete}
        form={oSelectedForm}
        delete={props.deleteForm}
        cancel={handleDialogClose}
      />
      <DeleteCollection
        open={bCollectionDeleteDialog}
        collectionName={sCollectionName}
        close={handleDialogClose}
        aDatabaseList={aDataBaselist}
      />
      <CreateDB open={bCreateDB} close={handleDialogClose} data={oDataFields} />
      <CreateApi
        open={bCreateApi}
        close={handleCloseCreateApi}
        create={handleNewApi}
      />
      <CreateRoute
        open={bCreateRoute}
        close={handleCloseCreateApi}
        geoapi={bGeo_API}
        selectedApi={sSelectedApi}
        selectedPath={sSelectedPath}
        apilist={aApiList}
        update={handleUpdateRoute}
      />
      <ApiAuthentication
        open={bAuthDialog}
        close={handleDialogClose}
        data={aAuthData}
        update={handleApiAuthUpdate}
      />
      <ApiPublish
        open={bApiPublish}
        close={handleDialogClose}
        data={aApiList}
      />
    </div>
  );
};
