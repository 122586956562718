import React, { useState, useEffect } from "react";
import "./propertiesRenderer.css";
import Server from "../../../../serverDetails/server";

//Dialogs
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { ColumnConfig } from "../../../../dialog/logics/table/columnConfig/ColumnConfig";
import { MapTableData } from "../../../../dialog/logics/table/mapTableData/MapTableData";
import { TabNameConfig } from "../../../../dialog/logics/tabs/tabNameconfig/TabNameConfig";
import { TabDisplayConfig } from "../../../../dialog/logics/tabs/tabDisplayConfig/TabDisplayConfig";
import { MapSingleData } from "../../../../dialog/logics/properties/mapSingleData/MapSingleData";
import { MapDropDownData } from "../../../../dialog/logics/properties/mapSingleData/MapDropDownData";
import { InsertDBProperties } from "../../../../dialog/logics/dbOperations/insertDBPropery/InsertDBProperty";
import { MrpCalculator } from "../../../../dialog/logics/properties/mrpCalculator/MrpCalculator";
import { InputValueHelpConfig } from "../../../../dialog/logics/properties/InputValueHelpConfig/InputValueHelpConfig";
import { ArithematicOperations } from "../../../../dialog/logics/properties/ArithematicOperations/ArithematicOperations";
import { TableArithematicCalculator } from "../../../../dialog/logics/table/tableCalculator/tableCalculator";
import { fetchTargetComponentValues } from "./targetComponentsValues/targetComponentValues";
import { UserInputsValidationDialog } from "../../../../dialog/logics/properties/validations/userInputsDialog/UserInputsDialog";
import { DynamicValidationMessagesDialog } from "../../../../dialog/logics/properties/validations/dynamicValidationMessage/ValidationMessage";
import { QueryConfigurationDialog } from "../../../../dialog/logics/dbOperations/queryConfiguration/QueryConfigurationDialog";
import { DeepQueryConfigurationDialog } from "../../../../dialog/logics/dbOperations/deepQueryConfiguration/deepQueryConfiguration";
import { DeleteCondition } from "../../../../dialog/logics/dbOperations/deleteConditions/DeleteCondition";
import { BarChartMapping } from "../../../../dialog/logics/charts/barCharts/BarChartMapping";
import { VisibilityDialog } from "../../../../dialog/logics/properties/ComponentVisibility/VisibilityDialog";
import { GanttChartDialog } from "../../../../dialog/logics/charts/ganttChart/GanttChartDialog";
import { G_GanttChartDialog } from "../../../../dialog/logics/charts/googleganttChart/G_GanttChartDialog";
import { MappingParamsDialog } from "../../../../dialog/logics/properties/navigation/mappingParams/MappingParams";
import { GetParamsDialog } from "../../../../dialog/logics/properties/navigation/getParams/getParamsDialog";
import { ConfirmationConditionDialog } from "../../../../dialog/logics/properties/Confirmation/ConfirmationConditionDialog";
import { InserDBSchema } from "../../../../dialog/logics/dbOperations/insertDBPropery/InsertDBSchema";
import { InsertDBCalculation } from "../../../../dialog/logics/dbOperations/insertDBPropery/calculationTable/CalculationTable";
import { AppNavigationDialog } from "../../../../dialog/logics/properties/navigation/appNavigation/AppNavigation";
import { CodeParameterDefinition } from "../../../../dialog/logics/properties/codeEditor/CodeParameterDefinitionDailog";
import { CodeWriterDialog } from "../../../../dialog/logics/properties/codeEditor/CodeWriterDialog";
import { DynamicRowLocking } from "../../../../dialog/logics/table/dynamicRowLocking/DynamicRowLocking";
import { FormDownloadDialog } from "../../../../dialog/logics/properties/FormDownload/FormDownloadDialog";
import { LoopConfigDialog } from "../../../../dialog/logics/form/LoopConfigDialog";
import { SendEmailDialog } from "../../../../dialog/logics/properties/sendEmail/SendEmailDialog";
import { ApiProperties } from "../../../../dialog/logics/properties/Api/ApiProperties";
import { SecurityDialog } from "../../../../dialog/logics/dbOperations/security/SecurityDialog";
import { MapViewConfig } from "../../../../dialog/logics/properties/mapView/MapViewConfig";
import { CsvDownload } from "../../../../dialog/logics/properties/csv/CsvDownload";

var aTables = [{ value: "", id: "" }];
var aPages = [{ name: "", id: "" }];
var aForms = [{ name: "", id: "" }];
let aInputs = [{ name: "", id: "" }];
var aTableColumns = [{ Fieldname: "", dataType: "" }];
var aGanttCharts = [{ value: "", id: "" }];
var aG_GanttCharts = [{ value: "", id: "" }];
var aBar_PieCharts = [{ value: "", id: "" }];
var aLocationMaps = [{ value: "", id: "" }];

function PropertiesRenderer(props) {
  const [bOpenColumnConfig, setOpenColumnConfig] = useState(false);
  const [aColumns, setColumns] = useState([]);
  const [iVisibleColumn, setColumnVisbile] = useState(0);
  const [bAdmin, setAdmin] = useState(false);
  const [bOpenMapTableData, setMapTableData] = useState(false);
  const [bOpenDBProperties, setOpenDBProperties] = useState(false);
  const [sMapTableName, setMapTableName] = useState("");
  const [aMapTableProperties, setMapTableProperties] = useState([]);
  const [aSourceDBFields, setSourceDBFields] = useState([]);
  const [oDefaultMapping, setDefaultMapping] = useState({});
  const [oDefaultSingleMapping, setDefaultSingleMapping] = useState({});
  const [bOpenMapSingleData, setOpenMapSingleData] = useState(false);
  const [oArraySingleMapping, setArraySingleMapping] = useState(false);
  const [bqueryConfig, setqueryConfig] = useState(false);
  const [bOpenDeleteConditionConfig, setOpenDeleteConditionConfig] =
    useState(false);
  const [aDeleteCondition, setDeleteCondition] = useState([]);
  const [sDeleteTableName, setDeleteTableName] = useState("");
  const [bDeepQueryConfig, setDeepQueryConfig] = useState(false);

  const [bOpenTabNameConfig, setTabNameConfig] = useState(false);
  const [iTabs, setTabs] = useState(0);
  const [aTabValues, setTabValues] = useState([]);
  const [oTabDisplayValues, setDisplayTabValues] = useState({});
  const [oTabComponents, setTabComponents] = useState({});
  const [sSelectedDisplayTab, setSelectedDisplayTab] = useState("");
  const [aDynamicTabComponents, setDynamicTabComponents] = useState([]);
  const [bOpenTabDisplayConfig, setOpenTabDisplayConfig] = useState(false);
  const [aInputFields, setInputFields] = useState([]);
  const [aOutputFields, setOutputFields] = useState([]);
  const [aFetchFields, setFetchFields] = useState([]);
  const [aDBCalculatedFields, setDBCalculatedFields] = useState([]);
  const [aValuehelpTableFields, setValueHelpTableFields] = useState([]);

  const [bOpenMrpCalculatorConfig, setMrpCalculatorConfig] = useState(false);
  const [aInputsFieldNames, setInputsFieldNames] = useState([]);
  const [oSelectionUserMrp, setSelectUserMrp] = useState({});

  const [bOpenValueHelpDialog, setOpenValueHelpDialog] = useState(false);
  const [iValuHelpRows, setValueHelpRows] = useState();
  const [aSourceDBFieldsInput, setSourceDBFieldsInput] = useState([]);
  const [oInputFieldsData, setInputFieldsData] = useState({});

  const [oData, setData] = useState({});

  const [bOpenArthematicOperations, setOpenArthematicOperations] =
    useState(false);
  const [aArithematicData, setArithematicData] = useState(false);
  const [aCalculatedColumns, setCalculatedColumns] = useState(false);
  const [oUpatedTableArithemicData, setUpatedTableArithemicData] = useState({});
  const [bOpenTableCalculator, setOpenTableCalculator] = useState(false);

  const [aSelectionArrayFields, setSelectionArrayFields] = useState({ "": "" });
  const [bOpenUserInputs, setOpenUserInputs] = useState(false);
  const [aUserInputsData, setUserInputsData] = useState([]);
  const [ajoinData, setjoinData] = useState({});
  const [bOpenMapBarChart, setOpenMapBarChart] = useState(false);
  const [aMapBarChartData, setMapBarChartData] = useState([]);
  const [bOpenComponentVisibility, setOpenComponentVisibility] =
    useState(false);
  const [oVisiblityComponents, setVisiblityComponents] = useState({});
  const [aSourceFields, setSourceFields] = useState([]);

  const [bGanttChartOpen, setGanttChartOpen] = useState(false);
  const [oGanttData, setGanttData] = useState({});
  const [bG_GanttChartOpen, setG_GanttChartOpen] = useState(false);
  const [oG_GanttData, setG_GanttData] = useState({});
  const [bMappingParamsOpen, setMappingParamsOpen] = useState(false);
  const [aMappingParamsData, setMappingParamsData] = useState([]);
  const [bgetParamsOpen, setgetParamsOpen] = useState(false);
  const [aGetParamsData, setGetParamsData] = useState([]);
  const [bOpenAppNavigation, setOpenAppNavigation] = useState(false);
  const [oAppNavigationData, setAppNavigationData] = useState({});

  const [bConfirmation, setConfirmation] = useState(false);
  const [aConfirmationConditions, setConfirmationConditions] = useState([]);

  const [bInsertDBSchema, setInsertDBSchema] = useState(false);
  const [oSchemaData, setSchemaData] = useState({});
  const [bOpenDBCalculation, setOpenDBCalculation] = useState(false);
  const [aDataBaselist, setDataBaselist] = useState([]);

  const [bcodeparameter, setcodeparameter] = useState(false);
  const [bcodeWriter, setCodeWriter] = useState(false);
  const [aCodeparamterDefinition, setCodeParameterDefinition] = useState([]);
  const [aCodeFields, setCodeFields] = useState([]);
  const [aCodeEditorinputs, setCodeEditorInputs] = useState([]);
  const [sCode, setCode] = useState();
  const [bOpenDynamicRowLocking, setOpenDynamicRowLocking] = useState(false);
  const [aMappingDynamicRowData, setMappingDynamicRowData] = useState([]);
  const [bOpenValidationMessage, setOpenValidationMessage] = useState(false);
  const [aValidationMessageData, setValidationMessageData] = useState([]);

  //Form Download States
  const [bFormDownloadDialog, setFormDownloadDialog] = useState(false);
  const [aFormMapFields, setFormMapFields] = useState([]);
  const [aFormArrayFields, setFormArrayFields] = useState([]);
  const [aFormMapComponents, setFormMapComponents] = useState([]);

  //Form LoopComponentStates
  const [bLoopConfigDialog, setLoopConfigDialog] = useState(false);
  const [aLoopPageData, setLoopPageData] = useState([]);
  const [aLoopConfigData, setLoopConfigData] = useState([]);
  const [bSendMailConfigDialog, setSendMailConfigDialog] = useState(false);
  const [asetMapEmailData, setMapEmailData] = useState({});

  //API STATES
  const [aApiList, setApiList] = useState([]);
  const [oRouteList, setRouteList] = useState({});
  const [sSelectedApi, setSelectedApi] = useState();
  const [bApiDialog, setApiDialog] = useState(false);
  const [aApiPayload, setApiPayload] = useState([]);

  //Security
  const [bSecureDialog, setSecureDialog] = useState(false);
  const [oSecurityData, setSecurityData] = useState({});

  //Map View
  const [bMapViewDialog, setMapViewDialog] = useState(false);
  const [oMapViewData, setMapViewData] = useState({});

  //DropDown
  const [bDropDownDialog, setDropDownDialog] = useState(false);

  //CSV Download
  const [bDownloadcsv, setDownloadcsv] = useState(false);
  const [oCsvData, setCsvData] = useState({});

  useEffect(() => {
    aTables = [{ value: "", id: "" }];
  }, [props.UIData]);

  const fetchDataBaseList = async () => {
    var result = [];
    var oPostData = {
      collections: [
        {
          collectionName: "tablenames",
          selectionFields: ["tableName", "fields"],
          selectSingle: "",
        },
      ],
      joinCondition: [
        {
          condition: "",
          fields: [
            {
              firstTableField: "",
              secondTableField: "",
            },
          ],
          firstTable: "",
          secondTable: "",
          joinName: "",
        },
      ],
      WHERE: [],
      bArrayCondition: false,
      ArrayWhere: [],
      ADMINAPP: false,
    };

    await Server.post("/logics/db/fetch", oPostData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    }).then(function (oResult) {
      result = oResult.data.Data;
    });

    return result;
  };

  const extractFields = (obj) => {
    let fieldsArray = [];

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key].fields) {
          fieldsArray = fieldsArray.concat(obj[key].fields);
        } else {
          fieldsArray.push(key);
        }
      }
    }

    return fieldsArray;
  };

  async function handleApiList() {
    var aApiData = [];
    await Server.get("/editor/api", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    }).then(function (response) {
      aApiData = response.data.Data;
    });
    return aApiData;
  }

  useEffect(() => {
    async function DBfetch() {
      try {
        if (props.selectedMenuItem === "workflow") {
          const aList = await handleApiList();
          if (aList.length !== aApiList.length) {
            const apiRoutes = aList.reduce((acc, api) => {
              acc[api.API_ID] = api.RouteDetail.map((route) => route.Path);
              return acc;
            }, {});
            setApiList(aList);
            setRouteList(apiRoutes);
          }
          if (props.selectedComponent) {
            props.selectedComponent.Logic.forEach((oComp) => {
              oComp.component.forEach((oLogs) => {
                if (oLogs.data.id === "idApiCall") {
                  oLogs.properties.forEach((oProps) => {
                    if (oProps.id === "idApiList") {
                      setSelectedApi(oProps.value);
                    }
                  });
                }
              });
            });
          }
          const aDBList = await fetchDataBaseList();
          if (aDBList.length !== aDataBaselist.length) {
            setDataBaselist(aDBList);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    DBfetch();
  }, [props.selectedMenuItem]);

  if (props) {
    var aProperties = props.selectedComponent.properties;
    var oFetchInputComponentProperties = "";

    function handlePageTable() {
      if (aTables.length === 1) {
        props.UIData.forEach(function (oComponent) {
          if (oComponent.Component === "Table") {
            var oTableNameProperty = oComponent.properties.find(
              function (oProperty) {
                return oProperty.id === "idInputTableName";
              }
            );
            aTables.push({
              name: oTableNameProperty.value,
              id: oComponent.uniqueId,
            });
          }
        });
      }
    }
    if (
      props.selectedMenuItem === "workflow" &&
      props.selectedLogicComponent &&
      Object.keys(props.selectedLogicComponent).length > 0
    ) {
      aProperties = props.selectedLogicComponent.properties;
      if (aProperties) {
        oFetchInputComponentProperties = aProperties.find(function (oProperty) {
          return oProperty.id === "idFetchInputComponents";
        });
        var oMapCsvToTable  = aProperties.find(function (oProperty) {
          return oProperty.id === "idMapCsvToTable";
        });
        var oFetchNavigationParameters = aProperties.find(function (oProperty) {
          return oProperty.id === "idNavigationParameters";
        });
        var oFetchDynamicRowLocking = aProperties.find(function (oProperty) {
          return oProperty.id === "idMapDynamicRowLocking";
        });
        var oFunctionCall = aProperties.find(function (oProperty) {
          return oProperty.id === "idFunctionComponent";
        });

        if (
          oFetchInputComponentProperties ||
          oFetchNavigationParameters ||
          oFetchDynamicRowLocking ||
          oMapCsvToTable
        ) {
          var aInputFieldNames = [];
          var aUserFields = [];
          var aFieldNames = [];
          var aFormDownloadFields = [];

          props.UIData.forEach(function (oComponent) {
            oComponent.properties.forEach(function (oProperty) {
              if (oProperty.id === "idInputFieldName") {
                aInputFieldNames.push(oProperty);
                aFieldNames.push(oProperty);
                aFormDownloadFields.push(oProperty);
                aUserFields.push({
                  ...oProperty,
                  uniqueID: oComponent.uniqueId,
                });
              }
              if (
                oProperty.id === "idGanttChartName" ||
                oProperty.id === "idGoogleGanttChartName" ||
                oProperty.id === "idBarChartName" ||
                oProperty.id === "idPieChartName"
              ) {
                aUserFields.push({
                  ...oProperty,
                  uniqueID: oComponent.uniqueId,
                });
              }
              if (oProperty.id === "idInputTableName") {
                aFieldNames.push(oProperty);
                aFormDownloadFields.push(oProperty);
                var oColumnConfigProperty = oComponent.properties.find(
                  function (oColumnProperty) {
                    return oColumnProperty.id === "idColumnConfig";
                  }
                );
                aUserFields.push({
                  ...oProperty,
                  uniqueID: oComponent.uniqueId,
                  columns: oColumnConfigProperty.value,
                });
              }
              if (
                oProperty.id === "idEditableTable" &&
                oProperty.value === true
              ) {
                oComponent.properties.forEach(function (oProperty) {
                  if (oProperty.id === "idInputTableName") {
                    aInputFieldNames.push(oProperty);
                  }
                });
              }
              if (oProperty.id === "idMapLocationName") {
                aFieldNames.push({ ...oProperty, value: "longitude" });
                aFieldNames.push({ ...oProperty, value: "latitude" });
              }
              if(oProperty.id === "idImageUploaderName") {
                aFieldNames.push(oProperty)
              }
              if(oProperty.id === "idCSVUploaderName") {
                aFieldNames.push(oProperty)
              }
            });
          });
        }

        if (oFunctionCall) {
          var aFieldNames = [];
          props.UIData.forEach(function (oComponent) {
            oComponent.properties.forEach(function (oProperty) {
              if (oProperty.id === "idInputFieldName") {
                aFieldNames.push(oProperty);
              }
              if (oProperty.id === "idInputTableName") {
                aFieldNames.push(oProperty);
              }
              if (oProperty.id === "idInputButtonName") {
                aFieldNames.push(oProperty);
              }
            });
          });
        }
        handlePageTable();
        if (aTableColumns.length === 1) {
          var tableName = aProperties.find(function (oProperty) {
            return oProperty.id === "idDropDownTable";
          });
          if (tableName) {
            props.UIData.forEach(function (oComponent) {
              if (oComponent.Component === "Table") {
                if (tableName.value === oComponent.uniqueId) {
                  var oTableColumn = oComponent.properties.find(
                    function (oProperty) {
                      return oProperty.id === "idColumnConfig";
                    }
                  );
                  oTableColumn.value.map(function (oInputFieldName) {
                    if (oInputFieldName.inputType === "inputHelp") {
                      aTableColumns.push({
                        Fieldname: oInputFieldName.fieldName,
                        dataType: oInputFieldName.dataType,
                      });
                    }
                  });
                }
              }
            });
          }
        }
        if (Object.keys(aSelectionArrayFields).length >= 1) {
          var oProperty = aProperties.find(function (oProperty) {
            return oProperty.id === "idDropDownSplitColumns";
          });
          if (oProperty) {
            var sLogicComponentID = props.selectedLogicComponent.id;
            var oTargetComponent = getTargetComponent(sLogicComponentID);
            if (oTargetComponent) {
              var oFieldProperty = oTargetComponent.properties.find(
                function (oComponent) {
                  return oComponent.id === "idFetchJoin";
                }
              );
              var oInputComponents = oTargetComponent.properties.find(
                function (oProperty) {
                  return oProperty.id === "idFetchInputComponents";
                }
              );
              var oTableSelection = oTargetComponent.properties.find(
                function (oProperty) {
                  return oProperty.id === "idDropDownTable";
                }
              );
              if (oTargetComponent.data.id === "idCodeEditor") {
                var oNewTargetComponent = getTargetComponent(
                  oTargetComponent.id
                );
                if (oNewTargetComponent) {
                  oFieldProperty = oNewTargetComponent.properties.find(
                    function (oComponent) {
                      return oComponent.id === "idFetchJoin";
                    }
                  );
                  oInputComponents = oNewTargetComponent.properties.find(
                    function (oProperty) {
                      return oProperty.id === "idFetchInputComponents";
                    }
                  );
                  oTableSelection = oNewTargetComponent.properties.find(
                    function (oProperty) {
                      return oProperty.id === "idDropDownTable";
                    }
                  );
                }
              }
              if (oFieldProperty) {
                if (oFieldProperty.array) {
                  const bAllKeyPresent = Object.keys(
                    oFieldProperty.array
                  ).every((key) => aSelectionArrayFields.hasOwnProperty(key));
                  if (bAllKeyPresent === false) {
                    setSelectionArrayFields((prevState) => ({
                      ...prevState,
                      ...oFieldProperty.array,
                    }));
                  }
                  if (oFieldProperty.Fields) {
                    oProperty.Fields = oFieldProperty.Fields;
                  }
                }
              } else if (oInputComponents) {
                if (oInputComponents.array) {
                  const bAllKeyPresent = Object.keys(
                    oInputComponents.array
                  ).every((key) => aSelectionArrayFields.hasOwnProperty(key));
                  if (bAllKeyPresent === false) {
                    setSelectionArrayFields((prevState) => ({
                      ...prevState,
                      ...oInputComponents.array,
                    }));
                  }
                }
              } else if (oTableSelection) {
                if (oTableSelection.value !== "") {
                  var oTableFields = {};
                  var sTableName;
                  var aArrayFields = [];
                  const oTable = props.UIData.find(
                    (oComponent) =>
                      oComponent.uniqueId === oTableSelection.value
                  );
                  if (oTable) {
                    oTable.properties.forEach(function (oProperty) {
                      if (oProperty.id === "idInputTableName") {
                        sTableName = oProperty.value;
                      }
                      if (oProperty.id === "idColumnConfig") {
                        aArrayFields = oProperty.value
                          .filter((item) => item.fieldName)
                          .map((item) => item.fieldName);
                      }
                    });
                    oTableFields[sTableName] = aArrayFields;
                    const bAllKeyPresent = Object.keys(oTableFields).every(
                      (key) => aSelectionArrayFields.hasOwnProperty(key)
                    );
                    if (bAllKeyPresent === false) {
                      setSelectionArrayFields((prevState) => ({
                        ...prevState,
                        ...oTableFields,
                      }));
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    if (
      props.selectedMenuItem === "validation" &&
      props.selectedValidationComponent
    ) {
      var aFieldNames = [];
      handlePageTable();
      aProperties = props.selectedValidationComponent.properties;
      if (aProperties) {
        oFetchInputComponentProperties = aProperties.find(function (oProperty) {
          return oProperty.id === "idFetchInputComponents";
        });
        if (oFetchInputComponentProperties) {
          props.UIData.forEach(function (oComponent) {
            oComponent.properties.forEach(function (oProperty) {
              if (oProperty.id === "idInputFieldName") {
                aFieldNames.push(oProperty);
              }
              if (oProperty.id === "idInputTableName") {
                aFieldNames.push(oProperty);
              }
            });
          });
        }
      }
    }

    var oTestUIData;
    if (aInputs.length === 1 || oTestUIData !== props.UIData) {
      aInputs = [{ value: "", id: "" }];
      props.UIData.forEach(function (oUIData) {
        if (oUIData.Component === "Input" || oUIData.Component === "DropDown") {
          let TableName = oUIData.properties.find(function (oProperty) {
            return oProperty.id === "idInputFieldName";
          });
          aInputs.push({
            name: TableName.value,
            id: oUIData.uniqueId,
          });
        }
      });
    }
    oTestUIData = props.UIData;

    if (aPages.length === 1) {
      Object.keys(props.appJson).forEach((oPage) => {
        if (oPage !== "forms") {
          var oNewPage = {};
          oNewPage.id = oPage;
          oNewPage.name = props.appJson[oPage].name;
          aPages.push(oNewPage);
        }
      });
    }
    if (aGanttCharts.length === 1) {
      props.UIData.forEach(function (oComponent) {
        if (oComponent.Component === "Gantt") {
          var oNewChart = {};
          oNewChart.id = oComponent.uniqueId;
          oNewChart.name = oComponent.properties.find(
            (oProperty) => oProperty.id === "idGanttChartName"
          ).value;
          aGanttCharts.push(oNewChart);
        }
      });
    }
    if (aG_GanttCharts.length === 1) {
      props.UIData.forEach(function (oComponent) {
        if (oComponent.Component === "G_Gantt") {
          var oNewChart = {};
          oNewChart.id = oComponent.uniqueId;
          oNewChart.name = oComponent.properties.find(
            (oProperty) => oProperty.id === "idGoogleGanttChartName"
          ).value;
          aG_GanttCharts.push(oNewChart);
        }
      });
    }

    if (aBar_PieCharts.length === 1) {
      props.UIData.forEach(function (oComponent) {
        if (oComponent.Component === "BarChart") {
          aBar_PieCharts.push(createChart(oComponent, "idBarChartName"));
        } else if (oComponent.Component === "PieChart") {
          aBar_PieCharts.push(createChart(oComponent, "idPieChartName"));
        }
      });
    }

    if (aLocationMaps.length === 1) {
      props.UIData.forEach(function (oComponent) {
        if (oComponent.Component === "MapLocation") {
          aLocationMaps.push(createChart(oComponent, "idMapLocationName"));
        } 
      });
    }
    function createChart(oComponent, propertyNameId) {
      let oNewChart = {};
      oNewChart.id = oComponent.uniqueId;
      oNewChart.name = oComponent.properties.find(
        (oProperty) => oProperty.id === propertyNameId
      ).value;
      return oNewChart;
    }

    if (aForms.length === 1) {
      if (props.appJson.forms) {
        Object.keys(props.appJson.forms).forEach((oForm) => {
          var oNewForm = {};
          oNewForm.id = oForm;
          oNewForm.name = props.appJson.forms[oForm].name;
          aForms.push(oNewForm);
        });
      }
    }

    /**
     * Event handle for onchange properties
     * @param {event} oEvent | Event
     * @param {string} sId | Property id
     */
    function handleChangeProperties(oEvent, sId) {
      var oUpdatedProperty = aProperties.find(function (oProperty) {
        return oProperty.id === sId;
      });
      if (oUpdatedProperty.id === "idApiList") {
        setSelectedApi(oEvent.target.value);
      }
      if (sId === "idFunctionComponent") {
        props.UIData.forEach(function (oAllData) {
          if (oAllData.Component === "Button") {
            oAllData.properties.forEach(function (oProperty) {
              if (
                oProperty.id === "idInputButtonName" &&
                oProperty.value === oEvent.target.value
              ) {
                oUpdatedProperty.uniqueId = oAllData.uniqueId;
              }
            });
          } else if (oAllData.Component === "Input") {
            oAllData.properties.forEach(function (oProperty) {
              if (
                oProperty.id === "idInputFieldName" &&
                oProperty.value === oEvent.target.value
              ) {
                oUpdatedProperty.uniqueId = oAllData.uniqueId;
              }
            });
          } else if (oAllData.Component === "Table") {
            oAllData.properties.forEach(function (oProperty) {
              if (
                oProperty.id === "idInputTableName" &&
                oProperty.value === oEvent.target.value
              ) {
                oUpdatedProperty.uniqueId = oAllData.uniqueId;
              }
            });
          }
        });
      }
      if (sId === "idInputTypeInput") {
        aProperties.forEach(function (oProperty) {
          if (oProperty.id === "idInputDefault") {
            if (
              oEvent.target.value === "Date" ||
              oEvent.target.value === "datetime-local"
            ) {
              oProperty.InputType = "dropdown";
            } else {
              oProperty.InputType = "input";
            }
          }
        });
      }

      oUpdatedProperty.value = oEvent.target.value;
      if (props.selectedMenuItem === "designer") {
        props.onUpdate(props.selectedComponent);
      } else if (props.selectedMenuItem === "workflow") {
        props.onUpdateLogic(props.selectedLogicComponent);
      } else if (props.selectedMenuItem === "form") {
        props.onUpdateForm(props.selectedComponent);
      } else if (props.selectedMenuItem === "validation") {
        props.onUpdateLogic(props.selectedValidationComponent);
      }
    }

    /**
     * Event handle for onchange properties for checkbox
     * @param {event} oEvent | Event
     * @param {string} sId | Property id
     */
    function handleChangeCheckBox(oEvent, sId) {
      var oUpdatedProperty = aProperties.find(function (oProperty) {
        return oProperty.id === sId;
      });
      oUpdatedProperty.value = oEvent.target.checked;
      if (props.selectedMenuItem === "designer") {
        props.onUpdate(props.selectedComponent);
      } else if (props.selectedMenuItem === "workflow") {
        props.onUpdateLogic(props.selectedLogicComponent);
      } else if (props.selectedMenuItem === "form") {
        props.onUpdateForm(props.selectedComponent);
      }
    }

    /**
     * Event handle for onchange properties and update ui only
     * @param {event} oEvent | Event
     * @param {string} sId | Property id
     */
    function handleChangeEvent(oEvent, sId) {
      var oUpdatedProperty = aProperties.find(function (oProperty) {
        return oProperty.id === sId;
      });
      var iTabindex = props.UIData.findIndex(function (oModel) {
        return oModel.Component === "Tabs";
      });
      if (iTabindex >= 0) {
        var oChangeTab = props.UIData[iTabindex];
        var iTabpropertyindex = oChangeTab.properties.findIndex(
          function (oModel) {
            return oModel.id === "idTabDisplayConfig";
          }
        );
        for (const atabProperty in oChangeTab.properties[iTabpropertyindex]
          .value) {
          for (const tab of oChangeTab.properties[iTabpropertyindex].value[
            atabProperty
          ]) {
            if (props.selectedComponent.uniqueId === tab.uniqueID) {
              tab.text = oEvent.target.value;
            }
          }
        }
        props.UIData[iTabindex] = oChangeTab;
      }
      oUpdatedProperty.value = oEvent.target.value;
      if (props.selectedMenuItem === "designer") {
        props.onUpdate(props.selectedComponent, true);
      } else if (props.selectedMenuItem === "workflow") {
        props.onUpdateLogic(props.selectedLogicComponent, true);
      } else if (props.selectedMenuItem === "form") {
        props.onUpdateForm(props.selectedComponent, true);
      }
    }

    /**
     * Even handler for tab config check box
     * @param {object} oEvent | Event
     * @param {object} oInputFieldName | input field name
     * @param {string} sId | id
     */
    function handleCheckbox(oEvent, oInputFieldName, sId) {
      var oUpdatedProperty = aProperties.find(function (oProperty) {
        return oProperty.id === sId;
      });
      if (oEvent.target.checked) {
        oUpdatedProperty.value.push(oInputFieldName.value);
        if (oInputFieldName.id === "idInputTableName") {
          const atableComponent = props.UIData.find(
            (oComponent) =>
              oComponent.Component === "Table" &&
              oComponent.properties.some(
                (o) =>
                  o.id === "idInputTableName" &&
                  o.value === oInputFieldName.value
              )
          );
          if (atableComponent) {
            const ocolumnConfigProperty = atableComponent.properties.find(
              (o) => o.id === "idColumnConfig"
            );

            if (ocolumnConfigProperty) {
              const afieldNamesArray = ocolumnConfigProperty.value.map(
                (field) => field.fieldName
              );
              const oUpdatedData = {
                [oInputFieldName.value]: afieldNamesArray,
              };
              oUpdatedProperty.array = oUpdatedData;
            }
          }
        }
      } else {
        var index = oUpdatedProperty.value.indexOf(oInputFieldName.value);
        if (index > -1) {
          oUpdatedProperty.value.splice(index, 1);
        }
      }
      props.onUpdateLogic(props.selectedLogicComponent);
    }

    /**
     * Even handler for tab config check box
     * @param {object} oInputFieldName | input field name
     * @param {string} sId | id
     */
    function handleRadioButton(oInputFieldName, sId) {
      var oUpdatedProperty = aProperties.find(function (oProperty) {
        return oProperty.id === sId;
      });
      oUpdatedProperty.value = oInputFieldName.value;
      props.onUpdateLogic(props.selectedLogicComponent);
    }

    /**
     * get logics TargetComponent
     * @param {string} sLogicComponentID | id
     */
    function getTargetComponent(sLogicComponentID) {
      if (!sLogicComponentID) {
        return null;
      }
      let oTargetComponent = null;
      let oLogicComponentEdgeID;
      props.selectedComponent.Logic.some(function (oLogic) {
        oLogic.edges.some(function (oEdge) {
          if (oEdge.target === sLogicComponentID) {
            oLogicComponentEdgeID = oEdge.source;
            return true; // break the loop
          }
          return false;
        });

        oLogic.component.some(function (oComponent) {
          if (oComponent.id === oLogicComponentEdgeID) {
            oTargetComponent = oComponent;
            return true; // break the loop
          }
          return false;
        });
        return oTargetComponent !== null;
      });
      return oTargetComponent;
    }

    /**
     * get validation TargetComponent
     * @param {string} sValidationComponentID | id
     */
    function getValidationTargetComponent(sValidationComponentID) {
      if (!sValidationComponentID) {
        return null;
      }
      let oTargetComponent = null;
      let oValidationComponentEdgeID;
      props.selectedComponent.Logic.some(function (oLogic) {
        oLogic.validations.some(function (oValidationLogic) {
          oValidationLogic.edges.some(function (oEdge) {
            if (oEdge.target === sValidationComponentID) {
              oValidationComponentEdgeID = oEdge.source;
              return true;
            }
            return false;
          });

          oValidationLogic.component.some(function (oComponent) {
            if (oComponent.id === oValidationComponentEdgeID) {
              oTargetComponent = oComponent;
              return true;
            }
            return false;
          });
        });
        return oTargetComponent !== null;
      });
      return oTargetComponent;
    }

    /**
     * Handle dialog configuration
     * @param {object} oProperty | Property of the components
     * @returns
     */
    function handleDialogConfig(oProperty) {
      switch (oProperty.dialogName) {
        case "ColumnConfig":
          setOpenColumnConfig(true);
          setColumns([...oProperty.value]);
          var iColumnVisible = props.selectedComponent.properties.find(
            function (oProperty) {
              return oProperty.id === "idVisibleColumns";
            }
          );
          setColumnVisbile(iColumnVisible.value);
          break;
        case "MapTableData":
          var aTableProperties;
          var aSourceDBFields;
          var oTargetComponent;
          setMapTableData(true);
          var sTableid = props.selectedLogicComponent.properties.find(
            function (oProperty) {
              return oProperty.id === "idDropDownTable";
            }
          );
          var oDefaultMapping = props.selectedLogicComponent.properties.find(
            function (oProperty) {
              return oProperty.id === "idMapTableFields";
            }
          );
          var sTableName = aTables.find(function (oTable) {
            return oTable.id === sTableid.value;
          });
          if (oDefaultMapping.value) {
            setDefaultMapping(oDefaultMapping.value);
          }
          props.UIData.forEach(function (oComponent) {
            if (oComponent.Component === "Table") {
              oComponent.properties.forEach(function (oProperty) {
                if (
                  oProperty.id === "idInputTableName" &&
                  oProperty.value === sTableName.name
                ) {
                  aTableProperties = oComponent.properties;
                  return;
                }
              });
            }
          });
          var sLogicComponentID = props.selectedLogicComponent.id;
          if (sLogicComponentID) {
            var oTargetComponent = getTargetComponent(sLogicComponentID);
            if (
              oTargetComponent.data.id === "idCodeEditor" ||
              oTargetComponent.data.id === "idMessageList"
            ) {
              oTargetComponent = getTargetComponent(oTargetComponent.id);
            }
            if (oTargetComponent) {
              aSourceDBFields = fetchTargetComponentValues(
                oTargetComponent,
                oProperty.dialogName,
                aSelectionArrayFields,
                props.UIData
              );
            }
          }
          if (oTargetComponent.data.id === "idFetchDBSplitColumns") {
            oTargetComponent.properties.forEach(function (oProperty) {
              if (oProperty.Fields) {
                aSourceDBFields = aSourceDBFields.concat(oProperty.Fields);
              }
            });
          }
          setSourceDBFields(aSourceDBFields);
          setMapTableName(sTableName.id);
          setMapTableProperties(aTableProperties);
          break;
        case "TabNameConfig":
          var iTabs = props.selectedComponent.properties.find(
            function (oProperty) {
              return oProperty.id === "idTabNumber";
            }
          );
          var aTabValues = props.selectedComponent.properties.find(
            function (oProperty) {
              return oProperty.id === "idTabNames";
            }
          );
          if (aTabValues.value.length > 0) {
            let index = parseInt(iTabs.value, 10);
            aTabValues.value = aTabValues.value.slice(0, index);
          }
          setTabValues(aTabValues.value);
          setTabNameConfig(true);
          setTabs(iTabs.value);
          break;
        case "TabDisplayConfig":
          var aTabValues = props.selectedComponent.properties.find(
            function (oProperty) {
              return oProperty.id === "idTabNames";
            }
          );
          var oTabDisplayConfigs = props.selectedComponent.properties.find(
            function (oProperty) {
              return oProperty.id === "idTabDisplayConfig";
            }
          );
          var oTabComponents = {};
          if (Object.keys(oTabDisplayConfigs.value).length > 0) {
            if (
              Object.keys(oTabDisplayConfigs.value).length <
              aTabValues.value.length
            ) {
              for (var i = 0; i < aTabValues.value.length; i++) {
                if (!oTabDisplayConfigs.value[aTabValues.value[i]]) {
                  oTabDisplayConfigs.value[aTabValues.value[i]] = [];
                  updateTabUIComponents(
                    oTabDisplayConfigs.value,
                    aTabValues,
                    i
                  );
                }
              }
            } else if (
              Object.keys(oTabDisplayConfigs.value).length >
              aTabValues.value.length
            ) {
              let oNewTabComponents = {};
              for (var i = 0; i < aTabValues.value.length; i++) {
                if (oTabDisplayConfigs.value[aTabValues.value[i]]) {
                  oNewTabComponents[aTabValues.value[i]] =
                    oTabDisplayConfigs.value[aTabValues.value[i]];
                }
              }
              oTabDisplayConfigs.value = oNewTabComponents;
            }
            oTabComponents = oTabDisplayConfigs.value;
            updateNewComponents(oTabComponents, props.UIData, aTabValues);
          } else {
            if (!sSelectedDisplayTab) {
              for (var i = 0; i < aTabValues.value.length; i++) {
                oTabComponents[aTabValues.value[i]] = [];
                updateTabUIComponents(oTabComponents, aTabValues, i);
              }
            } else {
              for (var i = 0; i < aTabValues.value.length; i++) {
                oTabComponents[aTabValues.value[i]] = [];
                props.UIData.forEach(function (oComponent) {
                  if (oComponent.Component !== "Tabs") {
                    var sComponentName = getComponentName(oComponent);
                    //oTabComponents[aTabValues.value[i]].push({ "uniqueID": oComponent.uniqueId, "text": sComponentName, "visible": true })
                    if (aTabValues.value[i] === sSelectedDisplayTab) {
                      oTabComponents[aTabValues.value[i]].push({
                        uniqueID: oComponent.uniqueId,
                        text: sComponentName,
                        visible: true,
                      });
                    } else {
                      oTabComponents[aTabValues.value[i]].push({
                        uniqueID: oComponent.uniqueId,
                        text: sComponentName,
                        visible: false,
                      });
                    }
                  }
                });
              }
            }
          }
          setTabValues(aTabValues.value);
          setSelectedDisplayTab(aTabValues.value[0]);
          setDisplayTabValues(oTabDisplayConfigs.value);
          setTabComponents(oTabComponents);
          setOpenTabDisplayConfig(true);
          updateDynamicComponents(
            oTabComponents[aTabValues.value[0]],
            oTabComponents,
            aTabValues.value[0]
          );
          break;
        case "MapSingleData":
          var oTargetComponent = "";
          var aSourceDBFields;
          var aOutputInputFields = [];

          var sLogicComponentID = props.selectedLogicComponent.id;

          var oDefaultSingleMapping =
            props.selectedLogicComponent.properties.find(function (oProperty) {
              return oProperty.id === "idMapInputFields";
            });
          setDefaultSingleMapping({ ...oDefaultSingleMapping.value });

          if (sLogicComponentID) {
            var oTargetComponent = getTargetComponent(sLogicComponentID);
            if (
              oTargetComponent.data.id === "idCodeEditor" ||
              oTargetComponent.data.id === "idMessageList"
            ) {
              oTargetComponent = getTargetComponent(oTargetComponent.id);
            }
            if (oTargetComponent) {
              aSourceDBFields = fetchTargetComponentValues(
                oTargetComponent,
                oProperty.dialogName,
                aSelectionArrayFields,
                props.UIData
              );
              if (oTargetComponent.data.id === "idFetchDB") {
                if (oTargetComponent.properties[0].array) {
                  var oArrayMapping = oTargetComponent.properties[0].array;
                  setArraySingleMapping(oArrayMapping);
                }
              }
            }
            props.UIData.forEach((oUIData) => {
              if (
                oUIData.Component === "Input" ||
                oUIData.Component === "DropDown"
              ) {
                var oProperties = oUIData.properties.find((oProperty) => {
                  return oProperty.id === "idInputFieldName";
                });
                aOutputInputFields.push({
                  id: oUIData.uniqueId,
                  name: oProperties.value,
                });
              }
              if (oUIData.Component === "Label") {
                var sLabelName;
                var sProperty;
                oUIData.properties.forEach((oProperty) => {
                  if (oProperty.id === "idInputLabelName") {
                    sLabelName = oProperty.value;
                  }
                  if (
                    oProperty.id === "idLabelDynamic" &&
                    oProperty.value === true
                  ) {
                    sProperty = sLabelName;
                  }
                });
                if (sProperty) {
                  aOutputInputFields.push({
                    id: oUIData.uniqueId,
                    name: sProperty,
                  });
                }
              }
              if (oUIData.Component === "Table") {
                var oProperties = oUIData.properties.find((oProperty) => {
                  return oProperty.id === "idInputTableName";
                });
                aOutputInputFields.push({
                  id: oUIData.uniqueId,
                  name: oProperties.value,
                });
              }
              if (oUIData.Component === "MapLocation") {
                var oProperties = oUIData.properties.find((oProperty) => {
                  return oProperty.id === "idMapLocationName";
                });
                aOutputInputFields.push({
                  id: oUIData.uniqueId,
                  name: oProperties.value,
                });
              }
              if(oUIData.Component === "Image"){
                var oProperties = oUIData.properties.find((oProperty) => {
                  return oProperty.id === "idImageUploaderName";
                });
                aOutputInputFields.push({
                  id: oUIData.uniqueId,
                  name: oProperties.value,
                });
              }
            });
            setOpenMapSingleData(true);
            setOutputFields([...aOutputInputFields]);
            setSourceDBFields([...aSourceDBFields]);
          }
          break;
        case "DropDownMap":
          var oTargetComponent = "";
          var aSourceDBFields;
          var aOutputInputFields = [];

          var sLogicComponentID = props.selectedLogicComponent.id;

          var oDefaultSingleMapping =
            props.selectedLogicComponent.properties.find(function (oProperty) {
              return oProperty.id === "idDropDownDialog";
            });
          setDefaultSingleMapping({ ...oDefaultSingleMapping.value });
          if (sLogicComponentID) {
            var oTargetComponent = getTargetComponent(sLogicComponentID);
            if (
              oTargetComponent.data.id === "idCodeEditor" ||
              oTargetComponent.data.id === "idMessageList"
            ) {
              oTargetComponent = getTargetComponent(oTargetComponent.id);
            }
            if (oTargetComponent) {
              aSourceDBFields = fetchTargetComponentValues(
                oTargetComponent,
                oProperty.dialogName,
                aSelectionArrayFields,
                props.UIData
              );
              if (oTargetComponent.data.id === "idFetchDB") {
                if (oTargetComponent.properties[0].array) {
                  var oArrayMapping = oTargetComponent.properties[0].array;
                  setArraySingleMapping(oArrayMapping);
                }
              }
            }
            props.UIData.forEach((oUIData) => {
              if (oUIData.Component === "DropDown") {
                var oProperties = oUIData.properties.find((oProperty) => {
                  return oProperty.id === "idInputFieldName";
                });
                aOutputInputFields.push({
                  id: oUIData.uniqueId,
                  name: oProperties.value,
                });
              }
            });
            setDropDownDialog(true);
            setOutputFields([...aOutputInputFields]);
            setSourceDBFields([...aSourceDBFields]);
          }
          break;
        case "InsertDBProperties":
          setOpenDBProperties(true);
          var sLogicComponentID = props.selectedLogicComponent.id;
          var bPropertyDefined = "";
          var aFields = fetchPreviousSelectionFields(sLogicComponentID);
          var aDefaultData = props.selectedLogicComponent.properties.find(
            function (oProperty) {
              return oProperty.id === "idDBTableProperties";
            }
          );
          var oTargetComponent = getTargetComponent(sLogicComponentID);
          if (oTargetComponent.data.id === "idFetchDBSplitColumns") {
            oTargetComponent.properties.forEach(function (oProperty) {
              if (oProperty.Fields) {
                if (!aFields) {
                  aFields = [];
                }
                aFields = aFields.concat(oProperty.Fields);
              }
            });
          }
          aFields.forEach((oField) => {
            if (aDefaultData.value.length > 0) {
              bPropertyDefined = aDefaultData.value.find((oData) => {
                return oData.value === oField;
              });
            }

            if (!bPropertyDefined) {
              aDefaultData.value.push({
                value: oField,
                key: "",
                auto: "",
                pattern: "",
                FieldName: oField,
              });
            }
          });
          setFetchFields([...aDefaultData.value]);
          break;
        case "InsertDBSchema":
          setInsertDBSchema(true);
          var oSchemaValue = props.selectedLogicComponent.properties.find(
            (oProperty) => oProperty.id === "idDBSchemaProperties"
          ).value;
          setSchemaData(oSchemaValue);
          break;
        case "InsertDBCalculations":
          setOpenDBCalculation(true);
          var oSchemaValue = props.selectedLogicComponent.properties.find(
            (oProperty) => oProperty.id === "idDBCalculationProperties"
          );
          setDBCalculatedFields([...oSchemaValue.value]);
          break;
        case "MRPCalculatorConfig":
          setMrpCalculatorConfig(true);
          var aInputs = [""];
          props.UIData.forEach(function (oComponent) {
            oComponent.properties.forEach(function (oProperty) {
              if (oProperty.id === "idInputFieldName") {
                aInputs.push(oProperty);
              }
            });
          });
          var oSelectionFields = props.selectedLogicComponent.properties.find(
            function (oProperty) {
              return oProperty.id === "idMRPRequiredFields";
            }
          );
          setSelectUserMrp(oSelectionFields.value);
          setInputsFieldNames([...aInputs]);
          break;
        case "ValueHelpDialog":
          let aSourceDBFieldsInput;
          setOpenValueHelpDialog(true);
          setMapTableData(true);
          var numberRows = props.selectedLogicComponent.properties.find(
            function (oProperty) {
              return oProperty.id === "idRowInput";
            }
          );
          var inputFieldData = props.selectedLogicComponent.properties.find(
            function (oProperty) {
              return oProperty.id === "idMapInputFields";
            }
          );
          var bMapTable = props.selectedLogicComponent.properties.find(
            function (oProperty) {
              return oProperty.id === "idMapTablecheckbox";
            }
          ).value;
          if (bMapTable) {
            var tableFieldData = props.selectedLogicComponent.properties.find(
              function (oProperty) {
                return oProperty.id === "idMapInputFields";
              }
            );
            if (tableFieldData) {
              var sComponentid;
              props.selectedLogicComponent.properties.forEach(
                function (oProperty) {
                  if (oProperty.id === "idDropDownTable") {
                    sComponentid = oProperty.value;
                  }
                }
              );
              if (sComponentid) {
                props.UIData.forEach(function (oComponent) {
                  if (oComponent.uniqueId === sComponentid) {
                    oComponent.properties.forEach(function (oProperty) {
                      if (oProperty.id === "idColumnConfig") {
                        setValueHelpTableFields(oProperty.value);
                      }
                    });
                  }
                });
              }
            }
          } else {
            setValueHelpTableFields();
          }

          if (
            inputFieldData !== undefined &&
            inputFieldData.value !== undefined &&
            Object.keys(inputFieldData.value).length > 0
          ) {
            setInputFieldsData(inputFieldData.value);
            setData(inputFieldData.value);
          }
          if (
            tableFieldData !== undefined &&
            tableFieldData.value !== undefined &&
            Object.keys(tableFieldData.value).length > 0
          ) {
            setInputFieldsData(tableFieldData.value);
            setData(tableFieldData.value);
          }
          if (numberRows.value !== undefined && numberRows.value !== "") {
            setValueHelpRows(numberRows.value);
          }
          var sLogicComponentID = props.selectedLogicComponent.id;
          if (sLogicComponentID) {
            var oTargetComponent = getTargetComponent(sLogicComponentID);
            if (oTargetComponent) {
              aSourceDBFieldsInput = fetchTargetComponentValues(
                oTargetComponent,
                oProperty.dialogName,
                aSelectionArrayFields,
                props.UIData
              );
            }
            if (oTargetComponent.data.id === "idFetchDBSplitColumns") {
              oTargetComponent.properties.forEach(function (oProperty) {
                if (oProperty.Fields) {
                  aSourceDBFieldsInput = aSourceDBFieldsInput.concat(
                    oProperty.Fields
                  );
                }
              });
            }
            setSourceDBFieldsInput(aSourceDBFieldsInput);
          }
          break;
        case "ArithematicOperations":
          var oTargetComponent = "";
          setOpenArthematicOperations(true);
          var sLogicComponentID = props.selectedLogicComponent.id;
          if (sLogicComponentID) {
            props.selectedComponent.Logic.forEach(function (oLogic) {
              oLogic.component.forEach(function (oComponent) {
                if (oComponent.id === sLogicComponentID) {
                  oTargetComponent = oComponent;
                  return;
                }
              });
            });
            if (oTargetComponent) {
              var oFieldProperty = oTargetComponent.properties.find(
                function (oComponent) {
                  return oComponent.id === "idCalculator";
                }
              );
              setArithematicData(oFieldProperty.value);
            }
          }
          break;
        case "TableArithematicCalculator":
          var sTableProperties;
          var aSelectedColumns = [];
          var sLogicComponentID = props.selectedLogicComponent.id;
          if (sLogicComponentID) {
            var oTargetComponent = getTargetComponent(sLogicComponentID);
            if (oTargetComponent) {
              var aFetchFields = fetchTargetComponentValues(
                oTargetComponent,
                oProperty.dialogName,
                aSelectionArrayFields
              );
              if (aFetchFields) {
                var sTableID = props.selectedLogicComponent.properties.find(
                  function (oProperty) {
                    return oProperty.id === "idDropDownTable";
                  }
                ).value;
                if (sTableID) {
                  props.UIData.forEach(function (oComponent) {
                    if (
                      oComponent.Component === "Table" &&
                      oComponent.uniqueId === sTableID
                    ) {
                      sTableProperties = oComponent.properties.find(
                        function (oProperty) {
                          return oProperty.id === "idColumnConfig";
                        }
                      );
                    }
                  });
                  if (sTableProperties) {
                    sTableProperties.value.forEach(function (oColumDetails) {
                      if (oColumDetails.dataType === "Number") {
                        aSelectedColumns.push({
                          fieldName: oColumDetails.fieldName,
                          operator: "",
                        });
                      }
                    });
                  }
                } else {
                  aSelectedColumns = aFetchFields;
                }
              }
              var oSelectionFields =
                props.selectedLogicComponent.properties.find(
                  function (oProperty) {
                    return oProperty.id === "idTableCalculator";
                  }
                );
              setUpatedTableArithemicData(oSelectionFields.value);
              setCalculatedColumns(aSelectedColumns);
              setOpenTableCalculator(true);
            }
          }
          break;
        case "UserInputs":
          setOpenUserInputs(true);
          var oUserInputsProperty =
            props.selectedValidationComponent.properties.find(
              function (oProperty) {
                return oProperty.id === "idUserInputsFields";
              }
            );
          var aInputs = [""];
          props.UIData.forEach(function (oComponent) {
            oComponent.properties.forEach(function (oProperty) {
              if (
                oProperty.id === "idInputFieldName" ||
                oProperty.id === "idInputTableName"
              ) {
                aInputs.push({
                  name: oProperty.value,
                  id: oComponent.uniqueId,
                });
              }
            });
          });
          setInputsFieldNames([...aInputs]);
          setUserInputsData([...oUserInputsProperty.value]);
          break;
        case "queryConfiguration":
          var aFetchInputs;
          setqueryConfig(true);
          var sAdminProperty = props.selectedLogicComponent.properties.find(
            function (oComponent) {
              return oComponent.id === "idAdmin";
            }
          ).value;
          var oFieldProperty = props.selectedLogicComponent.properties.find(
            function (oComponent) {
              return oComponent.id === "idFetchJoin";
            }
          );
          var sLogicComponentID = props.selectedLogicComponent.id;
          if (sLogicComponentID) {
            var oTargetComponent = getTargetComponent(sLogicComponentID);
            if (oTargetComponent) {
              if (oTargetComponent.data.id === "idExecuteConfirmation") {
                var oNewTargetComponent = getTargetComponent(
                  oTargetComponent.id
                );
                aFetchInputs = fetchTargetComponentValues(
                  oNewTargetComponent,
                  oProperty.dialogName,
                  [],
                  props.UIData
                );
              } else {
                aFetchInputs = fetchTargetComponentValues(
                  oTargetComponent,
                  oProperty.dialogName,
                  [],
                  props.UIData
                );
              }
            }
          }
          if (aFetchInputs[0] !== "") {
            aFetchInputs.splice(0, 0, "");
          }
          setInputFields([...aFetchInputs]);
          setjoinData(oFieldProperty.value);
          setAdmin(sAdminProperty);
          break;
        case "deepQueryConfiguration":
          var aFetchInputs;
          setDeepQueryConfig(true);
          var sAdminProperty = props.selectedLogicComponent.properties.find(
            function (oComponent) {
              return oComponent.id === "idAdmin";
            }
          ).value;
          var oFieldProperty = props.selectedLogicComponent.properties.find(
            function (oComponent) {
              return oComponent.id === "idDeepFetchJoin";
            }
          );
          var sLogicComponentID = props.selectedLogicComponent.id;
          if (sLogicComponentID) {
            var oTargetComponent = getTargetComponent(sLogicComponentID);
            if (oTargetComponent) {
              if (oTargetComponent.data.id === "idExecuteConfirmation") {
                var oNewTargetComponent = getTargetComponent(
                  oTargetComponent.id
                );
                aFetchInputs = fetchTargetComponentValues(
                  oNewTargetComponent,
                  oProperty.dialogName,
                  [],
                  props.UIData
                );
              } else {
                aFetchInputs = fetchTargetComponentValues(
                  oTargetComponent,
                  oProperty.dialogName,
                  [],
                  props.UIData
                );
              }
            }
          }
          if (aFetchInputs[0] !== "") {
            aFetchInputs.splice(0, 0, "");
          }
          setInputFields([...aFetchInputs]);
          setjoinData(oFieldProperty.value);
          setAdmin(sAdminProperty);
          break;
        case "DeleteCondition":
          setOpenDeleteConditionConfig(true);
          var oTargetComponent, oLogicComponentEdgeID;

          var sTableName = props.selectedLogicComponent.properties.find(
            function (oProperty) {
              return oProperty.id === "idDeleteDBName";
            }
          );
          var oConditons = props.selectedLogicComponent.properties.find(
            function (oProperty) {
              return oProperty.id === "idDeleteCondition";
            }
          );
          var sAdminProperty = props.selectedLogicComponent.properties.find(
            function (oProperty) {
              return oProperty.id === "idAdmin";
            }
          );

          var sLogicComponentID = props.selectedLogicComponent.id;
          props.selectedComponent.Logic.forEach(function (oLogic) {
            oLogic.edges.forEach(function (oEdge) {
              if (oEdge.target === sLogicComponentID) {
                oLogicComponentEdgeID = oEdge.source;
                return;
              }
            });
          });

          props.selectedComponent.Logic.forEach(function (oLogic) {
            oLogic.component.forEach(function (oComponent) {
              if (oComponent.id === oLogicComponentEdgeID) {
                oTargetComponent = oComponent;
                return;
              }
            });
          });
          if (oTargetComponent) {
            var oFieldProperty = oTargetComponent.properties.find(
              function (oComponent) {
                return oComponent.id === "idFetchInputComponents";
              }
            );
            if (oFieldProperty && oFieldProperty.value.length > 0) {
              var aInputs = [" "];
              aInputs.push(oFieldProperty.value);
              setInputFields([...aInputs]);
            }

            if (!oFieldProperty) {
              var oFieldProperty = oTargetComponent.properties.find(
                function (oComponent) {
                  return oComponent.id === "idParamterName";
                }
              );
              if (oFieldProperty) {
                var aInputs = [" "];
                aInputs.push(oFieldProperty.value);
                if (oFieldProperty && oFieldProperty.value.length > 0) {
                  setInputFields([...aInputs]);
                }
              }
            }
          }
          setDeleteTableName(sTableName.value);
          setDeleteCondition(oConditons.value);
          setAdmin(sAdminProperty.value);
          break;
        case "MapBarChart":
          var oTargetComponent = "";
          setOpenMapBarChart(true);
          var sLogicComponentID = props.selectedLogicComponent.id;
          var oDefaultSingleMapping =
            props.selectedLogicComponent.properties.find(function (oProperty) {
              return oProperty.id === "idMapBarChatFields";
            });
          setMapBarChartData([...oDefaultSingleMapping.value]);
          if (sLogicComponentID) {
            var oTargetComponent = getTargetComponent(sLogicComponentID);
            if (oTargetComponent.data.id === "idCodeEditor") {
              oTargetComponent = getTargetComponent(oTargetComponent.id);
            }
            if (oTargetComponent) {
              aSourceDBFields = fetchTargetComponentValues(
                oTargetComponent,
                oProperty.dialogName,
                aSelectionArrayFields
              );
              setSourceDBFields([...aSourceDBFields]);
            }
          }
          break;
        case "MapComponentVisibility":
          var aSourceDBFields;
          var aFields;
          var sLogicComponentID = props.selectedLogicComponent.id;
          var aFields = fetchPreviousSelectionFields(sLogicComponentID);
          if (sLogicComponentID && aFields === null) {
            var oTargetComponent = getTargetComponent(sLogicComponentID);
            if (oTargetComponent) {
              if (oTargetComponent.data.id === "idCodeEditor") {
                oTargetComponent = getTargetComponent(oTargetComponent.id);
              }
              aSourceDBFields = fetchTargetComponentValues(
                oTargetComponent,
                oProperty.dialogName,
                aSelectionArrayFields,
                props.UIData
              );
            }
          }
          var oComponentVisibility =
            props.selectedLogicComponent.properties.find(function (oProperty) {
              return oProperty.id === "idMapComonentsVisibility";
            });
          if (oComponentVisibility.value) {
            var aTempComponentsData = oComponentVisibility.value.components;
            var oPageList = props.selectedLogicComponent.properties.find(
              function (oProperty) {
                return oProperty.id === "idPageList";
              }
            );
            var aSelectedPageUIComponents =
              props.appJson[oPageList.value].UIComponents;
            if (
              aTempComponentsData.length <= aSelectedPageUIComponents.length
            ) {
              aSelectedPageUIComponents.forEach(function (oComponent) {
                var findItem = aTempComponentsData.find(
                  (oColumn) => oColumn.componentId === oComponent.uniqueId
                );
                if (!findItem) {
                  aTempComponentsData.push({
                    componentId: oComponent.uniqueId,
                    componentName: oComponent.properties[0].value,
                    visible: true,
                    readOnly: false,
                  });
                }
              });
            }
            if (
              aTempComponentsData.length >= aSelectedPageUIComponents.length
            ) {
              aTempComponentsData = aTempComponentsData.filter((item2) =>
                aSelectedPageUIComponents.some(
                  (item1) => item1.uniqueId === item2.componentId
                )
              );
            }
            oComponentVisibility.value.components = aTempComponentsData;
            setVisiblityComponents(oComponentVisibility.value);
            if (aFields && aFields.length > 0) {
              setSourceFields(aFields);
            } else {
              setSourceFields(aSourceDBFields);
            }
            setOpenComponentVisibility(true);
          }
          break;
        case "MapGanttChartData":
          var oTargetComponent;
          var aSourceDBFields;
          var sLogicComponentID = props.selectedLogicComponent.id;
          setGanttChartOpen(true);
          var oDefaultMapping = props.selectedLogicComponent.properties.find(
            function (oProperty) {
              return oProperty.id === "idMapGanttFields";
            }
          );
          setGanttData(oDefaultMapping.value);
          if (sLogicComponentID) {
            var oTargetComponent = getTargetComponent(sLogicComponentID);
            if (oTargetComponent) {
              aSourceDBFields = fetchTargetComponentValues(
                oTargetComponent,
                oProperty.dialogName,
                aSelectionArrayFields,
                props.UIData
              );
            }
          }
          if (oTargetComponent.data.id === "idFetchDBSplitColumns") {
            oTargetComponent.properties.forEach(function (oProperty) {
              if (oProperty.Fields) {
                aSourceDBFields = aSourceDBFields.concat(oProperty.Fields);
              }
            });
          }
          setSourceDBFields(aSourceDBFields);
          break;
        case "MapG_GanttChartData":
          var oTargetComponent;
          var aSourceDBFields;
          var sLogicComponentID = props.selectedLogicComponent.id;
          setG_GanttChartOpen(true);
          var oDefaultMapping = props.selectedLogicComponent.properties.find(
            function (oProperty) {
              return oProperty.id === "idMapG_GanttFields";
            }
          );
          setG_GanttData(oDefaultMapping.value);
          if (sLogicComponentID) {
            var oTargetComponent = getTargetComponent(sLogicComponentID);
            if (oTargetComponent) {
              aSourceDBFields = fetchTargetComponentValues(
                oTargetComponent,
                oProperty.dialogName,
                aSelectionArrayFields,
                props.UIData
              );
            }
          }
          if (oTargetComponent.data.id === "idFetchDBSplitColumns") {
            oTargetComponent.properties.forEach(function (oProperty) {
              if (oProperty.Fields) {
                aSourceDBFields = aSourceDBFields.concat(oProperty.Fields);
              }
            });
          }
          setSourceDBFields(aSourceDBFields);
          break;
        case "MappingParameters":
          setMappingParamsOpen(true);
          var oPropertyMapping = props.selectedLogicComponent.properties.find(
            function (oProperty) {
              return oProperty.id === "idNavigationParameters";
            }
          );
          setMappingParamsData([...oPropertyMapping.value]);
          break;
        case "FormMapData":
          setFormDownloadDialog(true);
          setFormMapFields(oProperty.value);

          var sLogicComponentID = props.selectedLogicComponent.id;
          const sSelectedForm = props.selectedLogicComponent.properties.find(
            (oProperty) => oProperty.id === "idFormName"
          ).value;
          const aSelectedForm = getFormComponents(sSelectedForm);
          let aJoinFormComponents = [];
          const aJoinFormProperty =
            props.selectedLogicComponent.properties.find(
              (oProperty) => oProperty.id === "idJoinForm"
            );
          if (aJoinFormProperty && aJoinFormProperty.value !== "") {
            const sJoinForm = props.selectedLogicComponent.properties.find(
              (oProperty) => oProperty.id === "idJoinFormName"
            ).value;
            aJoinFormComponents = getFormComponents(sJoinForm);
          }
          const aFormComponents = [...aSelectedForm, ...aJoinFormComponents];
          setFormMapComponents(aFormComponents);

          function getFormComponents(formName) {
            const form = Object.values(props.appJson.forms).find(
              (form) => form.name === formName
            );
            if (!form) return [];

            return form.UIComponents.map((item) => {
              if (item.Component === "Label") {
                const sLabelName =
                  item.properties.find((prop) => prop.id === "idInputLabelName")
                    ?.value || "";
                return {
                  name: sLabelName,
                  uniqueId: item.uniqueId,
                };
              } else if (item.Component === "Table") {
                const sTableName =
                  item.properties.find((prop) => prop.id === "idInputTableName")
                    ?.value || "";
                return {
                  name: sTableName,
                  uniqueId: item.uniqueId,
                };
              } else {
                return null;
              }
            }).filter((label) => label !== null);
          }

          if (sLogicComponentID) {
            var aArrayDatas = ["TableData"];
            var oTargetComponent = getTargetComponent(sLogicComponentID);
            if (oTargetComponent.data.id === "idCodeEditor") {
              oTargetComponent = getTargetComponent(oTargetComponent.id);
            }
            if (oTargetComponent) {
              var aSourceDBFields = fetchTargetComponentValues(
                oTargetComponent,
                oProperty.dialogName,
                oTargetComponent.data.id === "idFetchDBSplitColumns"
                  ? aSelectionArrayFields
                  : [],
                props.UIData
              );

              if (oTargetComponent.data.id === "idFetchInputs") {
                oTargetComponent.properties.forEach(function (Property) {
                  if (
                    Property.id === "idFetchInputComponents" &&
                    Property.array
                  ) {
                    aArrayDatas = Object.keys(Property.array);
                  }
                });
                aSourceDBFields = aSourceDBFields.filter(
                  (item) => !aArrayDatas.includes(item)
                );
              } else if (oTargetComponent.data.id === "idFetchDB") {
                var sArrayFields = oTargetComponent.properties.find(
                  (Property) => Property.id === "idFetchDBMapData"
                ).value;
                aArrayDatas.push(sArrayFields);
              } else if (oTargetComponent.data.id === "idFetchDBSplitColumns") {
                var sArrayFields = oTargetComponent.properties.find(
                  (Property) => Property.id === "idSplitMapData"
                ).value;
                aArrayDatas.push(sArrayFields);
                oTargetComponent.properties.forEach(function (oProperty) {
                  if (oProperty.Fields) {
                    aSourceDBFields = aSourceDBFields.concat(oProperty.Fields);
                  }
                });
              }
            }
            setSourceDBFields(aSourceDBFields);
            setFormArrayFields(aArrayDatas);
          }
          break;
        case "GetParameters":
          setgetParamsOpen(true);
          var oPropertyMapping = props.selectedLogicComponent.properties.find(
            function (oProperty) {
              return oProperty.id === "idGetParameters";
            }
          );
          setGetParamsData([...oPropertyMapping.value]);
          break;
        case "ConfirmationCondition":
          setConfirmation(true);
          var aPropertyValue = props.selectedLogicComponent.properties.find(
            (oProperty) => oProperty.id === "idConfirmationInputs"
          ).value;
          if (aPropertyValue) {
            setConfirmationConditions(aPropertyValue);
          }
          break;
        case "App Navigation":
          var oAppNavigation = props.selectedLogicComponent.properties.find(
            function (oProperty) {
              return oProperty.id === "idAppNavigationCheckBox";
            }
          );
          if (oAppNavigation && oAppNavigation.value) {
            setOpenAppNavigation(true);
            var oPropertyMapping = props.selectedLogicComponent.properties.find(
              function (oProperty) {
                return oProperty.id === "idAppNavigation";
              }
            );
            setAppNavigationData({ ...oPropertyMapping.value });
          }
          break;
        case "parameterdefinition":
          setcodeparameter(true);
          var oTargetComponent = getTargetComponent(
            props.selectedLogicComponent.id
          );
          var aPreviousFields = ["TableData"];
          if (oTargetComponent.data.id === "idFetchDB") {
            const sMappingArray = oTargetComponent.properties.find(
              (Property) => Property.id === "idFetchDBMapData"
            )?.value;
            if (sMappingArray && sMappingArray !== "") {
              aPreviousFields.push(sMappingArray);
            }
          } else if (oTargetComponent.data.id === "idDeepFetchDB") {
            const sMappingArray = oTargetComponent.properties.find(
              (Property) => Property.id === "idDeepFetchDBMapData"
            )?.value;
            if (sMappingArray && sMappingArray !== "") {
              aPreviousFields.push(sMappingArray);
            }
          } else if (oTargetComponent.data.id === "idFetchDBSplitColumns") {
            const sMappingArray = oTargetComponent.properties.find(
              (Property) => Property.id === "idSplitMapData"
            )?.value;
            if (sMappingArray && sMappingArray !== "") {
              aPreviousFields.push(sMappingArray);
            }
          } else if (oTargetComponent.data.id === "idTableSelection") {
            const sTableSelectionID = oTargetComponent.properties.find(
              (Property) => Property.id === "idDropDownTable"
            )?.value;
            var oTableComponent = props.UIData.find(
              (oComponent) => oComponent.uniqueId === sTableSelectionID
            );
            var sTableName = oTableComponent.properties.find(
              (oTableProperty) => oTableProperty.id === "idInputTableName"
            ).value;
            aPreviousFields = ["", sTableName];
          } else {
            aPreviousFields = fetchTargetComponentValues(
              oTargetComponent,
              oProperty.dialogName
            );
          }
          setCodeFields(aPreviousFields);

          var aCodeParameter = props.selectedLogicComponent.properties.find(
            (oProperty) => oProperty.id === "idparameterdefinition"
          ).value;
          setCodeParameterDefinition(aCodeParameter);
          break;
        case "codewriter":
          setCodeWriter(true);
          var aCodeinputs = props.selectedLogicComponent.properties.find(
            (oProperty) => oProperty.id === "idparameterdefinition"
          ).value;

          var sDefaultCode = props.selectedLogicComponent.properties.find(
            (oProperty) => oProperty.id === "idcodewriter"
          ).value;

          if (aCodeinputs) {
            setCodeEditorInputs(aCodeinputs);
          }
          if (sDefaultCode) {
            setCode(sDefaultCode);
          } else {
            setCode("");
          }
          break;
        case "MapDynamicRowLocking":
          setOpenDynamicRowLocking(true);
          var oPropertyMapping = props.selectedLogicComponent.properties.find(
            function (oProperty) {
              return oProperty.id === "idMapDynamicRowLocking";
            }
          );
          setMappingDynamicRowData([...oPropertyMapping.value]);
          break;
        case "validationQueryConfiguration":
          var aFetchInputs;
          setqueryConfig(true);
          var oFieldProperty =
            props.selectedValidationComponent.properties.find(
              function (oComponent) {
                return oComponent.id === "idFetchJoin";
              }
            );
          var sAdminProperty =
            props.selectedValidationComponent.properties.find(
              function (oComponent) {
                return oComponent.id === "idAdmin";
              }
            ).value;
          var sValidationComponentID = props.selectedValidationComponent.id;
          if (sValidationComponentID) {
            var oTargetComponent = getValidationTargetComponent(
              sValidationComponentID
            );
            if (oTargetComponent) {
              if (oTargetComponent.data.id === "idExecuteConfirmation") {
                var oNewTargetComponent = getValidationTargetComponent(
                  oTargetComponent.id
                );
                aFetchInputs = fetchTargetComponentValues(
                  oNewTargetComponent,
                  oProperty.dialogName,
                  [],
                  props.UIData
                );
              } else {
                aFetchInputs = fetchTargetComponentValues(
                  oTargetComponent,
                  oProperty.dialogName,
                  [],
                  props.UIData
                );
              }
            }
          }
          if (aFetchInputs[0] !== "") {
            aFetchInputs.splice(0, 0, "");
          }
          setInputFields([...aFetchInputs]);
          setjoinData(oFieldProperty.value);
          setAdmin(sAdminProperty);
          break;
        case "validationCodeWriter":
          setCodeWriter(true);
          var aCodeinputs = props.selectedValidationComponent.properties.find(
            (oProperty) => oProperty.id === "idparameterdefinition"
          ).value;

          var sDefaultCode = props.selectedValidationComponent.properties.find(
            (oProperty) => oProperty.id === "idcodewriter"
          ).value;

          if (aCodeinputs) {
            setCodeEditorInputs(aCodeinputs);
          }
          if (sDefaultCode) {
            setCode(sDefaultCode);
          } else {
            setCode("");
          }
          break;
        case "validationParameterdefinition":
          setcodeparameter(true);
          var oTargetComponent = getValidationTargetComponent(
            props.selectedValidationComponent.id
          );
          var aPreviousFields;
          if (oTargetComponent.data.id === "idFetchDB") {
            aPreviousFields = ["TableData"];
          } else if (oTargetComponent.data.id === "idTableSelection") {
            const sTableSelectionID = oTargetComponent.properties.find(
              (Property) => Property.id === "idDropDownTable"
            )?.value;
            var oTableComponent = props.UIData.find(
              (oComponent) => oComponent.uniqueId === sTableSelectionID
            );
            var sTableName = oTableComponent.properties.find(
              (oTableProperty) => oTableProperty.id === "idInputTableName"
            ).value;
            aPreviousFields = ["", sTableName];
          } else {
            aPreviousFields = fetchTargetComponentValues(
              oTargetComponent,
              oProperty.dialogName
            );
          }
          setCodeFields(aPreviousFields);

          var aCodeParameter =
            props.selectedValidationComponent.properties.find(
              (oProperty) => oProperty.id === "idparameterdefinition"
            ).value;
          setCodeParameterDefinition(aCodeParameter);
          break;
        case "DynamicValidation":
          setOpenValidationMessage(true);
          var oTargetComponent = getValidationTargetComponent(
            props.selectedValidationComponent.id
          );
          var aPreviousFields;
          if (oTargetComponent.data.id === "idCodeEditor") {
            var aParameterValues = oTargetComponent.properties.find(
              (oProperty) => oProperty.id === "idparameterdefinition"
            )?.value;
            let sTargetField = aParameterValues.find(
              (oParameterRow) => oParameterRow.Result === "Output"
            ).Parameters;
            aPreviousFields = [sTargetField];
          } else {
            aPreviousFields = fetchTargetComponentValues(
              oTargetComponent,
              oProperty.dialogName
            );
          }
          setCodeFields(aPreviousFields);

          var aMessagesData = props.selectedValidationComponent.properties.find(
            (oProperty) => oProperty.id === "idValidationMessageFields"
          ).value;
          setValidationMessageData(aMessagesData);
          break;
        case "LoopConfig":
          setLoopConfigDialog(true);
          var oPropertyMapping = props.selectedComponent.properties.find(
            function (oProperty) {
              return oProperty.id === "idLoopConfig";
            }
          ).value;
          setLoopConfigData(oPropertyMapping);
          var aPageFormComponents = [];
          props.UIData.forEach(function (oComponent) {
            if (oComponent.Component !== "Loop") {
              oComponent.properties.forEach(function (oComponentProp) {
                if (
                  oComponentProp.id === "idInputTableName" ||
                  oComponentProp.id === "idInputLabelName"
                ) {
                  var oData = {};
                  oData["id"] = oComponent.uniqueId;
                  oData["value"] = oComponentProp.value;
                  aPageFormComponents.push(oData);
                }
              });
            }
          });
          setLoopPageData(aPageFormComponents);
          break;
        case "MapSendEmail":
          var aSourceDBFields;
          var sLogicComponentID = props.selectedLogicComponent.id;
          setSendMailConfigDialog(true);
          var oPropertyMapping = props.selectedLogicComponent.properties.find(
            function (oProperty) {
              return oProperty.id === "idMapSendEmail";
            }
          ).value;
          if (sLogicComponentID) {
            var oTargetComponent = getTargetComponent(sLogicComponentID);
            if (oTargetComponent.data.id === "idCodeEditor") {
              oTargetComponent = getTargetComponent(oTargetComponent.id);
            }
            if (oTargetComponent) {
              aSourceDBFields = fetchTargetComponentValues(
                oTargetComponent,
                oProperty.dialogName,
                aSelectionArrayFields,
                props.UIData
              );
            }
          }
          setSourceDBFields([...aSourceDBFields]);
          setMapEmailData(oPropertyMapping);
          break;
        case "ApiDialog":
          var aFetchInputs;
          var oParameter = {};
          var sSelectedAPI;
          var sSelectedRoute;
          var sSelectedMethod;
          props.selectedLogicComponent.properties.forEach((oProps) => {
            if (oProps.id === "idApiList") {
              sSelectedAPI = oProps.value;
            } else if (oProps.id === "idRouteList") {
              sSelectedRoute = oProps.value;
            } else if (oProps.id === "idMethod") {
              sSelectedMethod = oProps.value;
            }
          });
          var aApiArrayFields = [];
          if (sSelectedAPI && sSelectedRoute && sSelectedMethod) {
            aApiList.forEach((oApiList) => {
              if (oApiList.API_ID === sSelectedAPI) {
                oApiList.RouteDetail.forEach((oRoute) => {
                  if (oRoute.Path === sSelectedRoute) {
                    oRoute.Methods.forEach((oMethods) => {
                      if (oMethods.MethodName === sSelectedMethod) {
                        oParameter = oMethods.Parameter;
                        aApiArrayFields = extractFields(
                          oMethods.ReturnParameters
                        );
                      }
                    });
                  }
                });
              }
            });
          }
          props.selectedLogicComponent.properties.forEach((oProps) => {
            if (oProps.id === "idApiPayloadConfig") {
              oProps.fields = aApiArrayFields;
            }
          });

          var sLogicComponentID = props.selectedLogicComponent.id;
          if (sLogicComponentID) {
            var oTargetComponent = getTargetComponent(sLogicComponentID);
            if (oTargetComponent) {
              if (
                oTargetComponent.data.id === "idExecuteConfirmation" ||
                oTargetComponent.data.id === "idCodeEditor"
              ) {
                oTargetComponent = getTargetComponent(oTargetComponent.id);
              }
              aFetchInputs = fetchTargetComponentValues(
                oTargetComponent,
                oProperty.dialogName,
                [],
                props.UIData
              );
            }
          }
          if (aFetchInputs[0] !== "") {
            aFetchInputs.splice(0, 0, "");
          }
          let aArrayRows = [];
          if (oProperty.value.length === 0) {
            for (let key in oParameter) {
              if (oParameter.hasOwnProperty(key)) {
                aArrayRows.push({
                  Parameter: key,
                  Value: "",
                  Datatype: oParameter[key].type,
                });
              }
            }
          } else {
            aArrayRows = oProperty.value;
          }
          setApiPayload(aArrayRows);
          setInputFields([...aFetchInputs]);
          setApiDialog(true);
          break;
        case "FetchSecurityConfig":
          var oSecData = {};
          if (props.auth && props.auth.length > 0) {
            props.auth.forEach((oData) => {
              if (
                oData.ID === props.selectedComponent.uniqueId &&
                props.selectLogic.event === oData.Logic &&
                oData.LogicID === props.selectedLogicComponent.id
              ) {
                oSecData.values = oData.Authentication.values;
                if (oData.Authentication.condition) {
                  oSecData.condition = oData.Authentication.condition;
                } else {
                  oSecData.condition = [];
                }
              }
            });
          }
          props.selectedLogicComponent.properties.forEach((oData) => {
            if (oData.id === "idDeepFetchJoin") {
              oSecData.tableName = oData.value.collections[0].collectionName;
              oSecData.Fields = oData.value.collections[0].selectionFields;
            }
            if (oData.id === "idFetchSecurityConfig") {
              if (oData.value.tableName !== "" && !oSecData.values) {
                oSecData = oData.value;
                oSecData.condition = [];
              }
            }
          });
          setSecurityData(oSecData);
          setSecureDialog(true);
          break;
        case "InsertSecurityConfig":
          const aInsertData = props.selectedLogicComponent.properties.find(
            (oProperty) => oProperty.id === "idDBTableProperties"
          ).value;
          const transformedData = aInsertData.map((item) => {
            if (item.item && item.itemName) {
              return `${item.itemName}.${item.FieldName}`;
            }
            return item.FieldName;
          });
          const sInsertCollectionName =
            props.selectedLogicComponent.properties.find(
              (oProperty) => oProperty.id === "idInsertDBName"
            ).value;
          var oSecData = {
            tableName: sInsertCollectionName,
            Fields: transformedData,
            values: "",
            condition: [],
          };
          if (props.auth && props.auth.length > 0) {
            props.auth.forEach((oData) => {
              if (
                oData.ID === props.selectedComponent.uniqueId &&
                props.selectLogic.event === oData.Logic &&
                oData.LogicID === props.selectedLogicComponent.id
              ) {
                oSecData.values = oData.Authentication.values;
                oSecData.condition = oData.Authentication.condition;
              }
            });
          }
          props.selectedLogicComponent.properties.forEach((oData) => {
            if (oData.id === "idInsertSecurityConfig") {
              if (oData.value.tableName !== "" && !oSecData.values) {
                oSecData = oData.value;
              }
            }
          });
          setSecurityData(oSecData);
          setSecureDialog(true);
          break;
        case "DeleteSecurityConfig":
          const aDeleteData = props.selectedLogicComponent.properties.find(
            (oProperty) => oProperty.id === "idDeleteCondition"
          ).value;
          const aTransformedData = aDeleteData.map((item) => {
            if (item.field && item.subfield) {
              return `${item.subfield}.${item.field}`;
            }
            return item.field;
          });
          const sDeleteCollectionName =
            props.selectedLogicComponent.properties.find(
              (oProperty) => oProperty.id === "idDeleteDBName"
            ).value;

          var oSecData = {
            tableName: sDeleteCollectionName,
            Fields: aTransformedData,
            values: "",
            condition: [],
          };
          if (props.auth && props.auth.length > 0) {
            props.auth.forEach((oData) => {
              if (
                oData.ID === props.selectedComponent.uniqueId &&
                props.selectLogic.event === oData.Logic &&
                oData.LogicID === props.selectedLogicComponent.id
              ) {
                oSecData.values = oData.Authentication.values;
                oSecData.condition = oData.Authentication.condition;
              }
            });
          }
          props.selectedLogicComponent.properties.forEach((oData) => {
            if (oData.id === "idDeleteSecurityConfig") {
              if (oData.value.tableName !== "" && !oSecData.values) {
                oSecData = oData.value;
                oSecData.condition = [];
              }
            }
          });
          setSecurityData(oSecData);
          setSecureDialog(true);
          break;
        case "MapViewConfig":
          const oMapData = props.selectedLogicComponent.properties.find(
            (oProperty) => oProperty.id === "idMapViewFields"
          ).value;
          var sLogicComponentID = props.selectedLogicComponent.id;
          if (sLogicComponentID) {
            var oTargetComponent = getTargetComponent(sLogicComponentID);
            if (oTargetComponent.data.id === "idCodeEditor") {
              oTargetComponent = getTargetComponent(oTargetComponent.id);
            }
            if (oTargetComponent) {
              aSourceDBFields = fetchTargetComponentValues(
                oTargetComponent,
                oProperty.dialogName,
                aSelectionArrayFields,
                props.UIData
              );
            }
            if (oTargetComponent.data.id === "idFetchDBSplitColumns") {
              oTargetComponent.properties.forEach(function (oProperty) {
                if (oProperty.Fields) {
                  aSourceDBFields = aSourceDBFields.concat(oProperty.Fields);
                }
              });
            }
          }
          setSourceDBFields([...aSourceDBFields]);
          setMapViewDialog(true);
          setMapViewData(oMapData);
          break;
        case "DownloadCSV":
          var aFetchInputs;
          const oDownloadCSVData = props.selectedLogicComponent.properties.find(
            (oProperty) => oProperty.id === "idMapJsontoCsv"
          ).value;
          var sLogicComponentID = props.selectedLogicComponent.id;
          if (sLogicComponentID) {
            var oTargetComponent = getTargetComponent(sLogicComponentID);
            if (oTargetComponent) {
              if (
                oTargetComponent.data.id === "idExecuteConfirmation" ||
                oTargetComponent.data.id === "idCodeEditor"
              ) {
                oTargetComponent = getTargetComponent(oTargetComponent.id);
              }
              aFetchInputs = fetchTargetComponentValues(
                oTargetComponent,
                oProperty.dialogName,
                [],
                props.UIData
              );
            }
          }
          if (aFetchInputs[0] !== "") {
            aFetchInputs.splice(0, 0, "");
          }
          setInputFields([...aFetchInputs]);
          setCsvData(oDownloadCSVData);
          setDownloadcsv(true);
          break;
        default:
          return null;
      }
    }

    function fetchPreviousSelectionFields(sLogicComponentID) {
      let oLogicComponentEdgeID;
      let oTargetComponent;

      // Find oLogicComponentEdgeID
      props.selectedComponent.Logic.some((oLogic) => {
        const edge = oLogic.edges.find(
          (oEdge) => oEdge.target === sLogicComponentID
        );
        if (edge) {
          oLogicComponentEdgeID = edge.source;
          return true;
        }
        return false;
      });

      // Find oTargetComponent
      props.selectedComponent.Logic.some((oLogic) => {
        const component = oLogic.component.find(
          (oComponent) => oComponent.id === oLogicComponentEdgeID
        );
        if (component) {
          oTargetComponent = component;
          return true;
        }
        return false;
      });
      if (oTargetComponent && oTargetComponent.data.id === "idCodeEditor") {
        oTargetComponent = getTargetComponent(oTargetComponent.id);
      }
      // Retrieve properties
      if (oTargetComponent) {
        const oFieldProperty = getPropertyById(
          oTargetComponent,
          "idFetchInputComponents"
        );
        const oSplitProperty = getPropertyById(
          oTargetComponent,
          "idDropDownSplitColumns"
        );

        if (oFieldProperty) {
          return oFieldProperty.value;
        } else if (oSplitProperty) {
          return aSelectionArrayFields[oSplitProperty.value];
        }
      }

      return null;
    }

    function getPropertyById(component, id) {
      return component.properties.find((property) => property.id === id);
    }

    /**
     * Used for tab configuration for updating New Tab UIComponents
     * @param {object} oTabComponents | Tab components
     * @param {array} aTabValues | array of tab values
     * @param {Number} iIndex| Index Number
     */
    function updateTabUIComponents(oTabComponents, aTabValues, iIndex) {
      props.UIData.forEach(function (oComponent) {
        if (oComponent.Component !== "Tabs") {
          var sComponentName = getComponentName(oComponent);
          //oTabComponents[aTabValues.value[i]].push({ "uniqueID": oComponent.uniqueId, "text": sComponentName, "visible": true })
          if (iIndex === 0) {
            oTabComponents[aTabValues.value[iIndex]].push({
              uniqueID: oComponent.uniqueId,
              text: sComponentName,
              visible: true,
            });
          } else {
            oTabComponents[aTabValues.value[iIndex]].push({
              uniqueID: oComponent.uniqueId,
              text: sComponentName,
              visible: false,
            });
          }
        }
      });
    }

    /**
     * Used for tab configuration for updating New Components
     * @param {object} oTabComponents | Tab components
     * @param {array} aUIData | All ui datas
     * @param {array} aTabValues | array of tab values
     */
    function updateNewComponents(oTabComponents, aUIData, aTabValues) {
      var aFirstObject = oTabComponents[Object.keys(oTabComponents)[0]];
      if (aFirstObject.length > 0) {
        if (aFirstObject.length >= aUIData.length) {
          aFirstObject.forEach(function (oObject) {
            var oDeletedObject = aUIData.find(function (oUIData) {
              return oUIData.uniqueId === oObject.uniqueID;
            });
            if (!oDeletedObject) {
              for (var i = 0; i < aTabValues.value.length; i++) {
                var oNewArray = oTabComponents[aTabValues.value[i]].filter(
                  function (obj) {
                    return obj.uniqueID !== oObject.uniqueID;
                  }
                );
                oTabComponents[aTabValues.value[i]] = oNewArray;
              }
            }
          });
        } else {
          aUIData.forEach(function (oComponent) {
            var oTabComponent = aFirstObject.find(function (oObject) {
              return oObject.uniqueID === oComponent.uniqueId;
            });
            if (oComponent.Component !== "Tabs" && !oTabComponent) {
              var sComponentName = getComponentName(oComponent);
              for (var i = 0; i < aTabValues.value.length; i++) {
                oTabComponents[aTabValues.value[i]].push({
                  uniqueID: oComponent.uniqueId,
                  text: sComponentName,
                  visible: true,
                });
              }
            }
          });
        }
      }
    }

    /**
     * Update dynamic components for tab control
     * @param {object} oDynamicComponents | components
     * @param {object} oTabComponents | Tab components
     * @param {string} sSelectTableName | selected table name
     */
    function updateDynamicComponents(
      oDynamicComponents,
      oTabComponents,
      sSelectTableName
    ) {
      var tempDynamicComponents = [];
      oDynamicComponents.forEach(function (oTabComponent) {
        tempDynamicComponents.push(
          <FormControlLabel
            control={
              <Checkbox
                checked={oTabComponent.visible}
                onChange={(oEvent) =>
                  handleCheckChange(
                    oEvent,
                    oTabComponent,
                    oTabComponents,
                    sSelectTableName
                  )
                }
              />
            }
            label={oTabComponent.text}
          />
        );
      });
      setDynamicTabComponents([...tempDynamicComponents]);
    }

    /**
     * Handle check change eanble/diable tab components
     * @param {oEvent} oEvent | event
     * @param {*} oTabComponentChange | tab components
     * @param {*} oTabComponents | tab components
     * @param {*} sSelectTableName | selected table name
     */
    function handleCheckChange(
      oEvent,
      oTabComponentChange,
      oTabComponents,
      sSelectTableName
    ) {
      var tempComponents = { ...oTabComponents };
      tempComponents[sSelectTableName].forEach(function (oComponent) {
        if (oComponent.uniqueID === oTabComponentChange.uniqueID) {
          if (oComponent.visible) {
            oComponent.visible = false;
          } else {
            oComponent.visible = true;
          }
        }
      });
      setTabComponents(tempComponents);
      updateDynamicComponents(
        tempComponents[sSelectTableName],
        tempComponents,
        sSelectTableName
      );
    }

    /**
     * Handle tab change
     * @param {integer} newValue | selected tab
     */
    function handleChangeTab(newValue) {
      setSelectedDisplayTab(newValue);
      updateDynamicComponents(
        oTabComponents[newValue],
        oTabComponents,
        newValue
      );
    }

    /**
     * Get Component name
     * @param {object} oComponent
     * @returns {string}| component name
     */
    function getComponentName(oComponent) {
      var oProperty;
      if (oComponent.Component === "Button") {
        oProperty = oComponent.properties.find(function (oProperty) {
          return oProperty.id === "idInputButtonName";
        });
      } else if (oComponent.Component === "Label") {
        oProperty = oComponent.properties.find(function (oProperty) {
          return oProperty.id === "idInputLabelName";
        });
      } else if (oComponent.Component === "Input") {
        oProperty = oComponent.properties.find(function (oProperty) {
          return oProperty.id === "idInputFieldName";
        });
      } else if (oComponent.Component === "Table") {
        oProperty = oComponent.properties.find(function (oProperty) {
          return oProperty.id === "idInputTableName";
        });
      } else if (oComponent.Component === "DropDown") {
        oProperty = oComponent.properties.find(function (oProperty) {
          return oProperty.id === "idInputFieldName";
        });
      }
      return oProperty.value;
    }

    /**
     * Event handler to update column description
     * @param {array} aColumnDesc | column description
     */
    function handleUpdateColumnConfig(aColumnDesc) {
      var oPropertyColumns = props.selectedComponent.properties.find(
        function (oProperty) {
          return oProperty.id === "idColumnConfig";
        }
      );
      oPropertyColumns.value = aColumnDesc;
      if (props.selectedMenuItem === "forms") {
        props.onUpdateForm(props.selectedComponent);
      } else {
        props.onUpdate(props.selectedComponent);
      }
      setOpenColumnConfig(false);
    }

    /**
     * Event handler to update tab names
     * @param {array} aTabsDesc | Tab name description
     */
    function handleTabNameUpdate(aTabsDesc) {
      var oPropertyTabs = props.selectedComponent.properties.find(
        function (oProperty) {
          return oProperty.id === "idTabNames";
        }
      );
      var oPropertyDisplayComponents = props.selectedComponent.properties.find(
        function (oProperty) {
          return oProperty.id === "idTabDisplayConfig";
        }
      );
      oPropertyTabs.value = aTabsDesc;
      var oNewTabs = {};
      if (Object.keys(oPropertyDisplayComponents.value).length > 0) {
        var i = 0;
        for (var oProp in oPropertyDisplayComponents.value) {
          oNewTabs[aTabsDesc[i]] = oPropertyDisplayComponents.value[oProp];
          i++;
        }
      }
      oPropertyDisplayComponents.value = oNewTabs;
      props.onUpdate(props.selectedComponent);
      setTabNameConfig(false);
    }

    /**
     * Event handler to close the configuration dialogs
     */
    function handleCloseColumnConfig() {
      setOpenColumnConfig(false);
      setMapTableData(false);
      setTabNameConfig(false);
      setOpenTabDisplayConfig(false);
      setOpenMapSingleData(false);
      setOpenDBProperties(false);
      setMrpCalculatorConfig(false);
      setOpenValueHelpDialog(false);
      setOpenArthematicOperations(false);
      setOpenTableCalculator(false);
      setOpenUserInputs(false);
      setqueryConfig(false);
      setDeepQueryConfig(false);
      setOpenDeleteConditionConfig(false);
      setOpenMapBarChart(false);
      setGanttChartOpen(false);
      setOpenComponentVisibility(false);
      setMappingParamsOpen(false);
      setgetParamsOpen(false);
      setConfirmation(false);
      setInsertDBSchema(false);
      setOpenAppNavigation(false);
      setOpenDBCalculation(false);
      setcodeparameter(false);
      setCodeWriter(false);
      setOpenDynamicRowLocking(false);
      setG_GanttChartOpen(false);
      setOpenValidationMessage(false);
      setFormDownloadDialog(false);
      setLoopConfigDialog(false);
      setSendMailConfigDialog(false);
      setApiDialog(false);
      setSecureDialog(false);
      setMapViewDialog(false);
      setDropDownDialog(false);
      setDownloadcsv(false)
    }

    /**
     * Map the data to the columns
     * @param {object} oMapping
     */
    function handleUpdateMapping(oMapping) {
      var oPropertyMapping = props.selectedLogicComponent.properties.find(
        function (oProperty) {
          return oProperty.id === "idMapTableFields";
        }
      );
      if (oPropertyMapping) {
        oPropertyMapping.value = oMapping;
        props.onUpdateLogic(props.selectedLogicComponent);
        handleCloseColumnConfig();
      }
    }

    /**
     * Update InputsArithematic Data
     * @param {Object} oMapping
     */
    function handleUpdateArithematic(oMapping) {
      var oPropertyMapping = props.selectedLogicComponent.properties.find(
        function (oProperty) {
          return oProperty.id === "idCalculator";
        }
      );
      oPropertyMapping.value = oMapping;
      props.onUpdateLogic(props.selectedLogicComponent);
      handleCloseColumnConfig();
    }

    /**
     * Update TableArithematicData
     * @param {Object} oMapping
     */
    function handleUpdateTableArithematic(oMapping) {
      var oPropertyMapping = props.selectedLogicComponent.properties.find(
        function (oProperty) {
          return oProperty.id === "idTableCalculator";
        }
      );
      oPropertyMapping.value = oMapping;
      props.onUpdateLogic(props.selectedLogicComponent);
      handleCloseColumnConfig();
    }

    /**
     * Update Map Bar Chart Data
     * @param {Object} oMapping
     */
    function handleMapBarChartData(oMapping) {
      var oPropertyMapping = props.selectedLogicComponent.properties.find(
        function (oProperty) {
          return oProperty.id === "idMapBarChatFields";
        }
      );
      oPropertyMapping.value = oMapping;
      props.onUpdateLogic(props.selectedLogicComponent);
      handleCloseColumnConfig();
    }

    /**
     * Update Map Component Visibility
     * @param {Object} oMapping
     */
    function handleUpdateComponentsVisibility(oMapping) {
      var oPropertyMapping = props.selectedLogicComponent.properties.find(
        function (oProperty) {
          return oProperty.id === "idMapComonentsVisibility";
        }
      );
      oPropertyMapping.value = oMapping;
      props.onUpdateLogic(props.selectedLogicComponent);
      handleCloseColumnConfig();
    }

    /**
     * Update userInputRequest of the MrpCalculator
     * @param {Object} oMapping
     */
    function handleUpdateMrpUserInput(oMapping) {
      var oPropertyMapping = props.selectedLogicComponent.properties.find(
        function (oProperty) {
          return oProperty.id === "idMRPRequiredFields";
        }
      );
      oPropertyMapping.value = oMapping;
      props.onUpdateLogic(props.selectedLogicComponent);
      handleCloseColumnConfig();
    }

    function handleUpdateValueHelpFields(valueHelpField) {
      var oPropertyMappingInputfield =
        props.selectedLogicComponent.properties.find(function (oProperty) {
          return oProperty.id === "idMapInputFields";
        });
      var oPropertyMappingTablefield =
        props.selectedLogicComponent.properties.find(function (oProperty) {
          return oProperty.id === "idMapTableFields";
        });
      if (oPropertyMappingInputfield !== undefined) {
        oPropertyMappingInputfield.value = valueHelpField;
        props.onUpdateLogic(props.selectedLogicComponent);
        handleCloseColumnConfig();
      }
      if (oPropertyMappingTablefield !== undefined) {
        oPropertyMappingTablefield.value = valueHelpField;
        props.onUpdateLogic(props.selectedLogicComponent);
        handleCloseColumnConfig();
      }
    }

    /**
     * Update Map input Fields Data
     * @param {object} oMapping
     */
    function hanldeUpdateSingleUpdate(oMapping) {
      var oPropertyMapping = props.selectedLogicComponent.properties.find(
        function (oProperty) {
          return oProperty.id === "idMapInputFields";
        }
      );
      oPropertyMapping.value = oMapping;
      props.onUpdateLogic(props.selectedLogicComponent);
      handleCloseColumnConfig();
    }

    /**
     * Update Map input Fields Data
     * @param {object} oMapping
     */
    function hanldeUpdateDropDown(oMapping) {
      var oPropertyMapping = props.selectedLogicComponent.properties.find(
        function (oProperty) {
          return oProperty.id === "idDropDownDialog";
        }
      );
      oPropertyMapping.value = oMapping;
      props.onUpdateLogic(props.selectedLogicComponent);
      handleCloseColumnConfig();
    }

    /**
     * Update UserInputs validation
     * @param {object} aUserInputsData
     */
    function handleUpdateUserInputs(aUserInputsData) {
      var oPropertyUserInputs =
        props.selectedValidationComponent.properties.find(function (oProperty) {
          return oProperty.id === "idUserInputsFields";
        });
      oPropertyUserInputs.value = aUserInputsData;
      props.onUpdateLogic(props.selectedValidationComponent);
      handleCloseColumnConfig();
    }

    /**
     * Update Validation Message Data
     * @param {object} aUserInputsData
     */
    function handleUpdateValidationMessages(aValidationMessageData) {
      var oMessageProperty = props.selectedValidationComponent.properties.find(
        function (oProperty) {
          return oProperty.id === "idValidationMessageFields";
        }
      );
      oMessageProperty.value = aValidationMessageData;
      props.onUpdateLogic(props.selectedValidationComponent);
      handleCloseColumnConfig();
    }

    /**
     * Update tab visibility updates
     * @param {object} oValue
     */
    function handleTabVisibilityUpdate() {
      var aTabDisplayConfigs = props.selectedComponent.properties.find(
        function (oProperty) {
          return oProperty.id === "idTabDisplayConfig";
        }
      );
      aTabDisplayConfigs.value = oTabComponents;
      props.onUpdate(props.selectedComponent);
      setOpenTabDisplayConfig(false);
    }

    /**
     * Update DB propeties
     * @param {array} aData
     */
    function handleDBProperties(aData) {
      var aDBProperties = props.selectedLogicComponent.properties.find(
        function (oProperty) {
          return oProperty.id === "idDBTableProperties";
        }
      );
      if (aData.length > 0) {
        aDBProperties.value = aData;
        props.onUpdate(props.selectedLogicComponent);
      }
      setOpenDBProperties(false);
    }

    /**
     * Update DB Schema
     * @param {object} oData
     */
    function handleDBSchema(oData) {
      var oSchemaValue = props.selectedLogicComponent.properties.find(
        (oProperty) => oProperty.id === "idDBSchemaProperties"
      );
      if (oData) {
        oSchemaValue.value = oData;
        props.onUpdate(props.selectedLogicComponent);
      }
      setInsertDBSchema(false);
    }

    /**
     * Update DB Calculations
     * @param {object} oData
     */
    function handleUpdateDBCalculation(oData) {
      var oSchemaValue = props.selectedLogicComponent.properties.find(
        (oProperty) => oProperty.id === "idDBCalculationProperties"
      );
      if (oData) {
        oSchemaValue.value = oData;
        props.onUpdate(props.selectedLogicComponent);
      }
      setOpenDBCalculation(false);
    }

    /**
     * Update Fetch Join Queries Data
     *  @param {object} selectedArrayFields
     * @param {array} selectedNonArrayFields
     */
    function handleUpdateQuery(selectedArrayFields, selectedNonArrayFields) {
      if (props.selectedMenuItem === "workflow") {
        var oDatas = props.selectedLogicComponent.properties.find(
          function (oProperty) {
            return oProperty.id === "idFetchJoin";
          }
        );
        oDatas.value = ajoinData;
        if (selectedArrayFields) {
          oDatas.array = selectedArrayFields;
          oDatas.Fields = selectedNonArrayFields;
        }
        props.onUpdateLogic(props.selectedLogicComponent);
      } else {
        var oFetchJoinProperty =
          props.selectedValidationComponent.properties.find(
            function (oProperty) {
              return oProperty.id === "idFetchJoin";
            }
          );
        oFetchJoinProperty.value = ajoinData;
        if (selectedArrayFields) {
          oFetchJoinProperty.array = selectedArrayFields;
          oFetchJoinProperty.Fields = selectedNonArrayFields;
        }
        props.onUpdateLogic(props.selectedValidationComponent);
      }
      setqueryConfig(false);
    }

    function handleDeepUpdateQuery(
      selectedArrayFields,
      selectedNonArrayFields
    ) {
      if (props.selectedMenuItem === "workflow") {
        var oDatas = props.selectedLogicComponent.properties.find(
          function (oProperty) {
            return oProperty.id === "idDeepFetchJoin";
          }
        );
        oDatas.value = ajoinData;
        if (selectedArrayFields) {
          oDatas.array = selectedArrayFields;
          oDatas.Fields = selectedNonArrayFields;
        }
        props.onUpdateLogic(props.selectedLogicComponent);
      } else {
        var oFetchJoinProperty =
          props.selectedValidationComponent.properties.find(
            function (oProperty) {
              return oProperty.id === "idDeepFetchJoin";
            }
          );
        oFetchJoinProperty.value = ajoinData;
        if (selectedArrayFields) {
          oFetchJoinProperty.array = selectedArrayFields;
          oFetchJoinProperty.Fields = selectedNonArrayFields;
        }
        props.onUpdateLogic(props.selectedValidationComponent);
      }
      setDeepQueryConfig(false);
    }

    /**
     * Update Confirm Conditions
     */
    function handleUpdateConfirmCondition() {
      var oDatas = props.selectedLogicComponent.properties.find(
        function (oProperty) {
          return oProperty.id === "idConfirmationInputs";
        }
      );
      oDatas.value = aConfirmationConditions;
      props.onUpdateLogic(props.selectedLogicComponent);
      setConfirmation(false);
    }

    /**
     * Update GannChart Data
     */
    function handleUpdateGanttData() {
      var oDatas = props.selectedLogicComponent.properties.find(
        (oProperty) => oProperty.id === "idMapGanttFields"
      );
      if (oDatas) {
        oDatas.value = oGanttData;
      }
      props.onUpdateLogic(props.selectedLogicComponent);
      setGanttChartOpen(false);
    }

    /**
     * Update GannChart Data
     */
    function handleUpdateG_GanttData() {
      var oDatas = props.selectedLogicComponent.properties.find(
        (oProperty) => oProperty.id === "idMapG_GanttFields"
      );
      if (oDatas) {
        oDatas.value = oG_GanttData;
      }
      props.onUpdateLogic(props.selectedLogicComponent);
      setG_GanttChartOpen(false);
    }

    /**
     * Update Mapping Params during Navigation
     */
    function handleUpdatMappingParams() {
      var oNavigationParameter = props.selectedLogicComponent.properties.find(
        (oProperty) => oProperty.id === "idNavigationParameters"
      );
      if (oNavigationParameter) {
        oNavigationParameter.value = aMappingParamsData;
      }
      props.onUpdateLogic(props.selectedLogicComponent);
      handleCloseColumnConfig();
    }

    /**
     * Update Mapping  Table Dynamic Row Locking
     */
    function handleUpdateDynamicRowLocking() {
      var oNavigationParameter = props.selectedLogicComponent.properties.find(
        (oProperty) => oProperty.id === "idMapDynamicRowLocking"
      );
      if (oNavigationParameter) {
        oNavigationParameter.value = aMappingDynamicRowData;
      }
      props.onUpdateLogic(props.selectedLogicComponent);
      handleCloseColumnConfig();
    }

    /**
     * Update Get Params Parameter
     */
    function handleUpdatGetParams() {
      var oGetParameters = props.selectedLogicComponent.properties.find(
        (oProperty) => oProperty.id === "idGetParameters"
      );
      if (oGetParameters) {
        oGetParameters.value = aGetParamsData;
      }
      props.onUpdateLogic(props.selectedLogicComponent);
      handleCloseColumnConfig();
    }

    /**
     * Update App Navigation Data
     */
    function handleUpdateAppNavigation(oMapping) {
      var oPropertyMapping = props.selectedLogicComponent.properties.find(
        function (oProperty) {
          return oProperty.id === "idAppNavigation";
        }
      );
      oPropertyMapping.value = oMapping;
      props.onUpdateLogic(props.selectedLogicComponent);
      handleCloseColumnConfig();
    }

    /**
     * Update Delete Conditions
     */
    function handleDeleteConditionUpdate(aConditions) {
      var oPropertyFetch = props.selectedLogicComponent.properties.find(
        function (oProperty) {
          return oProperty.id === "idDeleteCondition";
        }
      );
      oPropertyFetch.value = aConditions;
      props.onUpdateLogic(props.selectedComponent);
      handleCloseColumnConfig(false);
    }

    /**
     * Update Code Parameters
     */
    function handleUpdateCodeParamter() {
      if (props.selectedMenuItem === "workflow") {
        var oCodeProperty = props.selectedLogicComponent.properties.find(
          function (oProperty) {
            return oProperty.id === "idparameterdefinition";
          }
        );
        oCodeProperty.value = aCodeparamterDefinition;
        props.onUpdateLogic(props.selectedLogicComponent);
      } else {
        var oCodeProperty = props.selectedValidationComponent.properties.find(
          function (oProperty) {
            return oProperty.id === "idparameterdefinition";
          }
        );
        oCodeProperty.value = aCodeparamterDefinition;
        props.onUpdateLogic(props.selectedValidationComponent);
      }
      handleCloseColumnConfig();
    }

    /**
     * Update code
     */
    function handleUpdateCode(code) {
      if (props.selectedMenuItem === "workflow") {
        var oCodeProperty = props.selectedLogicComponent.properties.find(
          function (oProperty) {
            return oProperty.id === "idcodewriter";
          }
        );
        oCodeProperty.value = code;
        props.onUpdateLogic(props.selectedLogicComponent);
      } else {
        var oCodeProperty = props.selectedValidationComponent.properties.find(
          function (oProperty) {
            return oProperty.id === "idcodewriter";
          }
        );
        oCodeProperty.value = code;
        props.onUpdateLogic(props.selectedValidationComponent);
      }

      handleCloseColumnConfig();
    }

    function handleUpdateFormDialog() {
      var oDatas = props.selectedLogicComponent.properties.find(
        function (oProperty) {
          return oProperty.id === "idFormMapData";
        }
      );
      oDatas.value = aFormMapFields;
      props.onUpdateLogic(props.selectedLogicComponent);
      handleCloseColumnConfig();
    }

    function handleUpdateLoopConfig() {
      var oDatas = props.selectedComponent.properties.find(
        function (oProperty) {
          return oProperty.id === "idLoopConfig";
        }
      );
      oDatas.value = aLoopConfigData;
      props.onUpdateForm(props.selectedComponent);
      handleCloseColumnConfig();
    }

    /**
     * Update Email Config Data
     */
    function handleUpdateEmailData() {
      var oDatas = props.selectedLogicComponent.properties.find(
        function (oProperty) {
          return oProperty.id === "idMapSendEmail";
        }
      );
      oDatas.value = asetMapEmailData;
      props.onUpdateLogic(props.selectedLogicComponent);
      handleCloseColumnConfig();
    }

    function handleUpdateApiDialog(data) {
      var oDatas = props.selectedLogicComponent.properties.find(
        function (oProperty) {
          return oProperty.id === "idApiPayloadConfig";
        }
      );
      oDatas.value = data;
      props.onUpdateLogic(props.selectedLogicComponent);
      handleCloseColumnConfig();
    }

    async function handleUpdateSecurity(data) {
      var oVersionData = {
        Page: props.selectedpage.id,
        ID: props.selectedComponent.uniqueId,
        Logic: props.selectLogic.event,
        LogicID: props.selectedLogicComponent.id,
        Authentication: data,
      };
      props.loader(true);
      await Server.post(
        "/editor/version",
        {
          APP_ID: props.APPID,
          AUTHENTICATION_OBJECT: oVersionData,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
        .then(function (oResponse) {
          props.loader(false);
        })
        .catch(function (oError) {
          props.loader(false);
          console.log(oError);
        });
    }

    function handleUpdateMapView() {
      var oDatas = props.selectedLogicComponent.properties.find(
        function (oProperty) {
          return oProperty.id === "idMapViewFields";
        }
      );
      oDatas.value = oMapViewData;
      props.onUpdateLogic(props.selectedLogicComponent);
      handleCloseColumnConfig();
    }

    function handleUpdateCsvData() {
      var oDatas = props.selectedLogicComponent.properties.find(
        function (oProperty) {
          return oProperty.id === "idMapJsontoCsv";
        }
      );
      oDatas.value = oCsvData
      props.onUpdateLogic(props.selectedLogicComponent);
      handleCloseColumnConfig();
    }

    return (
      <div className="scrollable-container">
        {aProperties &&
          aProperties.map((oProperties) => (
            <div>
              <div className="propertiesTitle">
                <label key={oProperties.id} className="Properties-Label">
                  {oProperties.title}
                </label>
              </div>
              <div className="propertiesValue">
                {oProperties.InputType === "input" && (
                  <input
                    className="propertiesValue-Input"
                    value={oProperties.value}
                    onMouseLeave={(oEvent) =>
                      handleChangeProperties(oEvent, oProperties.id)
                    }
                    onChange={(oEvent) =>
                      handleChangeEvent(oEvent, oProperties.id)
                    }
                  />
                )}
                {oProperties.InputType === "checkbox" && (
                  <input
                    type="checkbox"
                    className="propertiesValue-Input"
                    value={oProperties.title}
                    checked={oProperties.value}
                    onChange={(oEvent) =>
                      handleChangeCheckBox(oEvent, oProperties.id)
                    }
                  />
                )}

                {localStorage.getItem("ADMIN") &&
                  oProperties.InputType === "AdminCheckbox" && (
                    <input
                      type="checkbox"
                      className="propertiesValue-Input"
                      value={oProperties.title}
                      checked={oProperties.value}
                      onChange={(oEvent) =>
                        handleChangeCheckBox(oEvent, oProperties.id)
                      }
                    />
                  )}

                {oProperties.InputType === "textarea" && (
                  <textarea
                    rows="4"
                    cols="16"
                    onChange={(oEvent) =>
                      handleChangeProperties(oEvent, oProperties.id)
                    }
                    value={oProperties.value}
                  ></textarea>
                )}

                {oProperties.InputType === "dropdown" && (
                  <select
                    className="propertiesValue-Select"
                    value={oProperties.value}
                    onChange={(oEvent) =>
                      handleChangeProperties(oEvent, oProperties.id)
                    }
                  >
                    {oProperties.options.map(function (sOption) {
                      return (
                        <option key={sOption.value} value={sOption.value}>
                          {sOption.value}
                        </option>
                      );
                    })}
                  </select>
                )}
                {oProperties.InputType === "customDropdown" &&
                  oProperties.options === "functionrecall" && (
                    <select
                      className="propertiesValue-Select"
                      value={oProperties.value}
                      onChange={(oEvent) =>
                        handleChangeProperties(oEvent, oProperties.id)
                      }
                    >
                      <option value="" disabled selected>
                        Select Component
                      </option>
                      {aFieldNames &&
                        aFieldNames.map(function (oFieldName) {
                          return (
                            <option
                              key={oFieldName.value}
                              value={oFieldName.value}
                            >
                              {oFieldName.value}
                            </option>
                          );
                        })}
                    </select>
                  )}

                {oProperties.InputType === "customDropdown" &&
                  oProperties.options === "Tables" && (
                    <select
                      className="propertiesValue-Select"
                      value={oProperties.value}
                      onChange={(oEvent) =>
                        handleChangeProperties(oEvent, oProperties.id)
                      }
                    >
                      {aTables.map(function (oTable) {
                        return (
                          <option key={oTable.id} value={oTable.id}>
                            {oTable.name}
                          </option>
                        );
                      })}
                    </select>
                  )}
                {oProperties.InputType === "customDropdown" &&
                  oProperties.options === "TableColumns" && (
                    <select
                      className="propertiesValue-Select"
                      value={oProperties.value}
                      onChange={(oEvent) =>
                        handleChangeProperties(oEvent, oProperties.id)
                      }
                    >
                      {aTableColumns.map(function (oTable) {
                        return (
                          <option key={oTable.id}>{oTable.Fieldname}</option>
                        );
                      })}
                    </select>
                  )}
                {oProperties.InputType === "customDropdown" &&
                  oProperties.id === "idDropDownSplitColumns" && (
                    <select
                      className="propertiesValue-Select"
                      value={oProperties.value}
                      onChange={(oEvent) =>
                        handleChangeProperties(oEvent, oProperties.id)
                      }
                    >
                      {Object.keys(aSelectionArrayFields) &&
                        Object.keys(aSelectionArrayFields).map(
                          function (oTable) {
                            return <option key={oTable}>{oTable}</option>;
                          }
                        )}
                    </select>
                  )}
                {oProperties.InputType === "customDropdown" &&
                  oProperties.options === "Inputs" && (
                    <select
                      className="propertiesValue-Select"
                      value={oProperties.value}
                      onChange={(oEvent) =>
                        handleChangeProperties(oEvent, oProperties.id)
                      }
                    >
                      {aInputs.map(function (inp) {
                        return <option key={inp.id}>{inp.name}</option>;
                      })}
                    </select>
                  )}

                {oProperties.InputType === "customDropdown" &&
                  oProperties.options === "Forms" && (
                    <select
                      className="propertiesValue-Select"
                      value={oProperties.value}
                      onChange={(oEvent) =>
                        handleChangeProperties(oEvent, oProperties.id)
                      }
                    >
                      {aForms.map(function (form) {
                        return <option key={form.id}>{form.name}</option>;
                      })}
                    </select>
                  )}

                {oProperties.InputType === "customDropdown" &&
                  oProperties.options === "JoinForm" && (
                    <select
                      className="propertiesValue-Select"
                      value={oProperties.value}
                      onChange={(oEvent) =>
                        handleChangeProperties(oEvent, oProperties.id)
                      }
                    >
                      {aForms.map(function (form) {
                        return <option key={form.id}>{form.name}</option>;
                      })}
                    </select>
                  )}

                {oProperties.InputType === "customDropdown" &&
                  oProperties.options === "GanttChart" && (
                    <select
                      className="propertiesValue-Select"
                      value={oProperties.value}
                      onChange={(oEvent) =>
                        handleChangeProperties(oEvent, oProperties.id)
                      }
                    >
                      {aGanttCharts &&
                        aGanttCharts.map(function (oChart) {
                          return (
                            <option key={oChart.id} value={oChart.id}>
                              {oChart.name}
                            </option>
                          );
                        })}
                    </select>
                  )}
                {oProperties.InputType === "customDropdown" &&
                  oProperties.options === "G_GanttChart" && (
                    <select
                      className="propertiesValue-Select"
                      value={oProperties.value}
                      onChange={(oEvent) =>
                        handleChangeProperties(oEvent, oProperties.id)
                      }
                    >
                      {aG_GanttCharts &&
                        aG_GanttCharts.map(function (oChart) {
                          return (
                            <option key={oChart.id} value={oChart.id}>
                              {oChart.name}
                            </option>
                          );
                        })}
                    </select>
                  )}

                {oProperties.InputType === "customDropdown" &&
                  oProperties.options === "BarChart" && (
                    <select
                      className="propertiesValue-Select"
                      value={oProperties.value}
                      onChange={(oEvent) =>
                        handleChangeProperties(oEvent, oProperties.id)
                      }
                    >
                      {aBar_PieCharts &&
                        aBar_PieCharts.map(function (oChart) {
                          return (
                            <option key={oChart.id} value={oChart.id}>
                              {oChart.name}
                            </option>
                          );
                        })}
                    </select>
                  )}

                {oProperties.InputType === "customDropdown" &&
                  oProperties.options === "MapView" && (
                    <select
                      className="propertiesValue-Select"
                      value={oProperties.value}
                      onChange={(oEvent) =>
                        handleChangeProperties(oEvent, oProperties.id)
                      }
                    >
                      {aLocationMaps &&
                        aLocationMaps.map(function (oMapaLocation) {
                          return (
                            <option
                              key={oMapaLocation.id}
                              value={oMapaLocation.id}
                            >
                              {oMapaLocation.name}
                            </option>
                          );
                        })}
                    </select>
                  )}

                {oProperties.InputType === "customDropdown" &&
                  oProperties.options === "DBName" && (
                    <select
                      className="propertiesValue-Select"
                      value={oProperties.value}
                      onChange={(oEvent) =>
                        handleChangeProperties(oEvent, oProperties.id)
                      }
                    >
                      <option disabled value="">
                        Select DBName
                      </option>
                      {aDataBaselist &&
                        aDataBaselist.map(function (oChart) {
                          return (
                            <option
                              key={oChart.tableName}
                              value={oChart.tableName}
                            >
                              {oChart.tableName}
                            </option>
                          );
                        })}
                    </select>
                  )}
                {oProperties.InputType === "customDropdown" &&
                  oProperties.options === "ApiList" && (
                    <select
                      className="propertiesValue-Select"
                      value={oProperties.value}
                      onChange={(oEvent) =>
                        handleChangeProperties(oEvent, oProperties.id)
                      }
                    >
                      <option disabled value="">
                        Select Api
                      </option>
                      {aApiList &&
                        aApiList.map(function (oChart) {
                          return (
                            <option key={oChart.API_ID} value={oChart.API_ID}>
                              {oChart.API_DESCRIPTION}
                            </option>
                          );
                        })}
                    </select>
                  )}

                {oProperties.InputType === "customDropdown" &&
                  oProperties.options === "RouteList" && (
                    <select
                      className="propertiesValue-Select"
                      value={oProperties.value}
                      onChange={(oEvent) =>
                        handleChangeProperties(oEvent, oProperties.id)
                      }
                    >
                      <option disabled value="">
                        Select Route
                      </option>
                      {oRouteList &&
                        sSelectedApi &&
                        oRouteList[sSelectedApi].map(function (oChart) {
                          return (
                            <option key={oChart} value={oChart}>
                              {oChart}
                            </option>
                          );
                        })}
                    </select>
                  )}

                {oProperties.InputType === "customDropdown" &&
                  oProperties.options === "CsvMapTable" && (
                    <select
                      className="propertiesValue-Select"
                      value={oProperties.value}
                      onChange={(oEvent) =>
                        handleChangeProperties(oEvent, oProperties.id)
                      }
                    >
                      {aTables.map(function (oTable) {
                        return (
                          <option key={oTable.id} value={oTable.id}>
                            {oTable.name}
                          </option>
                        );
                      })}
                    </select>
                  )}

                {oProperties.InputType === "customDropdown" &&
                  oProperties.options === "CsvMapData" && (
                    <select
                      className="propertiesValue-Select"
                      value={oProperties.value}
                      onChange={(oEvent) =>
                        handleChangeProperties(oEvent, oProperties.id)
                      }
                    >
                      <option value="" disabled selected>
                        Select Component
                      </option>
                      {
                        aFieldNames.map(function (oFieldName) {
                          return (
                            <option
                              key={oFieldName.value}
                              value={oFieldName.value}
                            >
                              {oFieldName.value}
                            </option>
                          );
                        })}
                    </select>
                  )}

                {oProperties.InputType === "PageDropdown" && (
                  <select
                    className="propertiesValue-Select"
                    value={oProperties.value}
                    onChange={(oEvent) =>
                      handleChangeProperties(oEvent, oProperties.id)
                    }
                  >
                    {aPages &&
                      aPages.map(function (oPage) {
                        return (
                          <option key={oPage.id} value={oPage.id}>
                            {oPage.name}
                          </option>
                        );
                      })}
                  </select>
                )}

                {oProperties.InputType === "inputComponentsCheckBox" && (
                  <div>
                    {aFieldNames.map((oInputFieldName) => {
                      const isChecked = oProperties.value.includes(
                        oInputFieldName.value
                      );
                      return (
                        <div
                          className="propertiesValue-Checkbox"
                          key={oInputFieldName.value}
                        >
                          <br />
                          <input
                            type="checkbox"
                            checked={isChecked}
                            onClick={(oEvent) =>
                              handleCheckbox(
                                oEvent,
                                oInputFieldName,
                                oProperties.id
                              )
                            }
                          />
                          &nbsp;&nbsp;
                          <label style={{ color: "white" }}>
                            {oInputFieldName.value}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                )}
                {oProperties.InputType === "inputComponentsRadioButton" &&
                  aInputFieldNames.map(function (oInputFieldName) {
                    return (
                      <div className="propertiesValue-RadioButton">
                        <br></br>
                        {oProperties.value !== oInputFieldName.value && (
                          <input
                            type="radio"
                            name="inputFields"
                            onClick={(oEvent) =>
                              handleRadioButton(
                                oEvent,
                                oInputFieldName,
                                oProperties.id
                              )
                            }
                          />
                        )}
                        {oProperties.value === oInputFieldName.value && (
                          <input
                            type="radio"
                            name="inputFields"
                            checked
                            onClick={(oEvent) =>
                              handleRadioButton(
                                oEvent,
                                oInputFieldName,
                                oProperties.id
                              )
                            }
                          />
                        )}
                        &nbsp;&nbsp;
                        <label> {oInputFieldName.value}</label>
                      </div>
                    );
                  })}

                {oProperties.InputType === "dialog" && (
                  <IconButton
                    size="small"
                    onClick={(oEvent) => {
                      handleDialogConfig(oProperties);
                    }}
                  >
                    <SettingsIcon />
                  </IconButton>
                )}
                <br></br>
              </div>
              <ColumnConfig
                open={bOpenColumnConfig}
                close={handleCloseColumnConfig}
                columns={aColumns}
                iVisibleColumns={iVisibleColumn}
                update={handleUpdateColumnConfig}
              />
              <MapTableData
                open={bOpenMapTableData}
                close={handleCloseColumnConfig}
                inputFields={aSourceDBFields}
                tableName={sMapTableName}
                mapping={oDefaultMapping}
                properties={aMapTableProperties}
                update={handleUpdateMapping}
              />
              <MapSingleData
                open={bOpenMapSingleData}
                close={handleCloseColumnConfig}
                inputFields={aSourceDBFields}
                outputFields={aOutputFields}
                mapping={oDefaultSingleMapping}
                arraymapping={oArraySingleMapping}
                update={hanldeUpdateSingleUpdate}
              />
              <MapDropDownData
                open={bDropDownDialog}
                close={handleCloseColumnConfig}
                inputFields={aSourceDBFields}
                outputFields={aOutputFields}
                mapping={oDefaultSingleMapping}
                arraymapping={oArraySingleMapping}
                update={hanldeUpdateDropDown}
              />
              <TabNameConfig
                open={bOpenTabNameConfig}
                tabs={iTabs}
                values={aTabValues}
                close={handleCloseColumnConfig}
                update={handleTabNameUpdate}
              />
              <TabDisplayConfig
                open={bOpenTabDisplayConfig}
                components={props.UIData}
                tabValues={aTabValues}
                selectedTab={sSelectedDisplayTab}
                tabComponents={oTabComponents}
                close={handleCloseColumnConfig}
                update={handleTabVisibilityUpdate}
                changeTab={handleChangeTab}
                componentVisibilities={oTabDisplayValues}
                dynamicComponets={aDynamicTabComponents}
              />
              <InsertDBProperties
                open={bOpenDBProperties}
                close={handleCloseColumnConfig}
                fields={aFetchFields}
                updateData={handleDBProperties}
              />
              <MrpCalculator
                open={bOpenMrpCalculatorConfig}
                close={handleCloseColumnConfig}
                inputFieldNames={aInputsFieldNames}
                userSelectInput={oSelectionUserMrp}
                update={handleUpdateMrpUserInput}
              />
              <InputValueHelpConfig
                open={bOpenValueHelpDialog}
                close={handleCloseColumnConfig}
                row={iValuHelpRows}
                inputFieldsData={oInputFieldsData}
                aDBFieldNames={aSourceDBFieldsInput}
                update={handleUpdateValueHelpFields}
                setData={setData}
                oData={oData}
                aInputs={aInputs}
                tablefields={aValuehelpTableFields}
              />
              <ArithematicOperations
                open={bOpenArthematicOperations}
                close={handleCloseColumnConfig}
                fields={aInputs}
                update={handleUpdateArithematic}
                aColumns={aArithematicData}
                setColumns={setArithematicData}
              />
              <TableArithematicCalculator
                open={bOpenTableCalculator}
                close={handleCloseColumnConfig}
                fields={aCalculatedColumns}
                update={handleUpdateTableArithematic}
                handleUpdate={setUpatedTableArithemicData}
                rows={oUpatedTableArithemicData}
              />
              <UserInputsValidationDialog
                open={bOpenUserInputs}
                close={handleCloseColumnConfig}
                fields={aInputsFieldNames}
                aUserInputsData={aUserInputsData}
                update={handleUpdateUserInputs}
                setUserInputsData={setUserInputsData}
              />
              <QueryConfigurationDialog
                open={bqueryConfig}
                close={handleCloseColumnConfig}
                rows={ajoinData}
                updaterow={setjoinData}
                CID={props.CID}
                update={handleUpdateQuery}
                inputs={aInputFields}
                admin={bAdmin}
              />
              <DeepQueryConfigurationDialog
                open={bDeepQueryConfig}
                close={handleCloseColumnConfig}
                rows={ajoinData}
                updaterow={setjoinData}
                CID={props.CID}
                update={handleDeepUpdateQuery}
                inputs={aInputFields}
                admin={bAdmin}
              />
              <DeleteCondition
                open={bOpenDeleteConditionConfig}
                close={handleCloseColumnConfig}
                table={sDeleteTableName}
                inputs={aInputFields}
                update={handleDeleteConditionUpdate}
                condition={aDeleteCondition}
                CID={props.CID}
                admin={bAdmin}
              />
              <BarChartMapping
                open={bOpenMapBarChart}
                close={handleCloseColumnConfig}
                fields={aSourceDBFields}
                update={handleMapBarChartData}
                aColumns={aMapBarChartData}
                setColumns={setMapBarChartData}
              />
              <VisibilityDialog
                open={bOpenComponentVisibility}
                close={handleCloseColumnConfig}
                update={handleUpdateComponentsVisibility}
                fields={aSourceFields}
                oVisibilityData={oVisiblityComponents}
                handleUpdate={setVisiblityComponents}
              />
              <GanttChartDialog
                open={bGanttChartOpen}
                close={handleCloseColumnConfig}
                inputFields={aSourceDBFields}
                oData={oGanttData}
                updateData={setGanttData}
                update={handleUpdateGanttData}
              />
              <G_GanttChartDialog
                open={bG_GanttChartOpen}
                close={handleCloseColumnConfig}
                inputFields={aSourceDBFields}
                oData={oG_GanttData}
                updateData={setG_GanttData}
                update={handleUpdateG_GanttData}
              />
              <MappingParamsDialog
                open={bMappingParamsOpen}
                close={handleCloseColumnConfig}
                mappingParamsData={aMappingParamsData}
                setMappingParams={setMappingParamsData}
                inputFields={aUserFields}
                update={handleUpdatMappingParams}
              />
              <GetParamsDialog
                open={bgetParamsOpen}
                close={handleCloseColumnConfig}
                mappingParamsData={aGetParamsData}
                setMappingParams={setGetParamsData}
                update={handleUpdatGetParams}
              />
              <ConfirmationConditionDialog
                open={bConfirmation}
                close={handleCloseColumnConfig}
                rows={aConfirmationConditions}
                updateData={setConfirmationConditions}
                inputFields={aInputs}
                update={handleUpdateConfirmCondition}
              />
              <InserDBSchema
                open={bInsertDBSchema}
                close={handleCloseColumnConfig}
                data={oSchemaData}
                updatedata={setSchemaData}
                update={handleDBSchema}
              />
              <InsertDBCalculation
                open={bOpenDBCalculation}
                close={handleCloseColumnConfig}
                rows={aDBCalculatedFields}
                update={setDBCalculatedFields}
                handleUpdate={handleUpdateDBCalculation}
              />
              <AppNavigationDialog
                open={bOpenAppNavigation}
                close={handleCloseColumnConfig}
                userId={props.userId}
                update={handleUpdateAppNavigation}
                projectID={props.projectID}
                oAppNavigationData={oAppNavigationData}
                aColumns={oVisiblityComponents}
                error={props.error}
              />
              <CodeParameterDefinition
                open={bcodeparameter}
                close={handleCloseColumnConfig}
                rows={aCodeparamterDefinition}
                updateData={setCodeParameterDefinition}
                fields={aCodeFields}
                update={handleUpdateCodeParamter}
              />
              <CodeWriterDialog
                open={bcodeWriter}
                close={handleCloseColumnConfig}
                inputs={aCodeEditorinputs}
                defaultcode={sCode}
                update={handleUpdateCode}
              />
              <DynamicRowLocking
                open={bOpenDynamicRowLocking}
                close={handleCloseColumnConfig}
                dynamicRowLockingData={aMappingDynamicRowData}
                setMapDynamicRowData={setMappingDynamicRowData}
                inputFields={aUserFields}
                update={handleUpdateDynamicRowLocking}
              />
              <DynamicValidationMessagesDialog
                open={bOpenValidationMessage}
                close={handleCloseColumnConfig}
                fields={aCodeFields}
                aValidationMessageData={aValidationMessageData}
                update={handleUpdateValidationMessages}
                setValidationMessageData={setValidationMessageData}
              />
              <FormDownloadDialog
                open={bFormDownloadDialog}
                close={handleCloseColumnConfig}
                data={aFormMapFields}
                updateData={setFormMapFields}
                arrayFields={aFormArrayFields}
                fields={aSourceDBFields}
                formComponents={aFormMapComponents}
                update={handleUpdateFormDialog}
              />
              <LoopConfigDialog
                open={bLoopConfigDialog}
                close={handleCloseColumnConfig}
                fields={aLoopPageData}
                data={aLoopConfigData}
                updateData={setLoopConfigData}
                update={handleUpdateLoopConfig}
              />
              <SendEmailDialog
                open={bSendMailConfigDialog}
                close={handleCloseColumnConfig}
                setData={setMapEmailData}
                mapMailData={asetMapEmailData}
                update={handleUpdateEmailData}
                aSourceFields={aSourceDBFields}
              />
              <ApiProperties
                open={bApiDialog}
                close={handleCloseColumnConfig}
                inputs={aInputFields}
                rows={aApiPayload}
                update={handleUpdateApiDialog}
              />
              <SecurityDialog
                open={bSecureDialog}
                close={handleCloseColumnConfig}
                data={oSecurityData}
                update={handleUpdateSecurity}
              />
              <MapViewConfig
                open={bMapViewDialog}
                close={handleCloseColumnConfig}
                mapView={oMapViewData}
                sourceFields={aSourceDBFields}
                update={handleUpdateMapView}
              />
              <CsvDownload
                open={bDownloadcsv}
                close={handleCloseColumnConfig}
                inputs={aInputFields}
                data={oCsvData}
                updatedata={setCsvData}
                update={handleUpdateCsvData}
              />
            </div>
          ))}
      </div>
    );
  }
}
export default PropertiesRenderer;
