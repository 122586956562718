import React, { useState, useEffect } from "react";

//Material UI Components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import Server from "../../../serverDetails/server";

export const CreateApi = (props) => {
  const [apiDescription, setApiDescription] = useState("");
  const [sFeatID, setFeatID] = useState("");
  const [bGeoAPI, setGeoAPI] = useState(false);
  const [aFeatures, setFeatures] = useState([]);

  function handleCancel() {
    props.close();
  }
  function handleConfirm() {
    props.create(apiDescription, bGeoAPI, sFeatID);
  }

  async function getFunctionData() {
    if (localStorage.getItem("CID") === "SYSTEM") {
      await Server.get("/editor/projects", {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          if (response.data.Projects) {
            setFeatures(response.data.Features);
          }
        })
        .catch(function (oError) {
          if (oError.response.data) {
            props.error(oError.response.data.Message);
          } else {
            props.error(oError.message);
          }
        });
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await getFunctionData();
    };
    fetchData();
  }, []);

  const handleChange = (event) => {
    setGeoAPI(event.target.checked);
  };

  return (
    <Dialog open={props.open} maxWidth="sm" fullWidth={true}>
      <DialogTitle>{"Create New API"}</DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <label
            htmlFor="api-description"
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "5px",
            }}
          >
            API Description
          </label>
          <input
            id="api-description"
            type="text"
            placeholder="Enter API description"
            style={{
              padding: "10px",
              fontSize: "14px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              outline: "none",
              transition: "border-color 0.3s",
              width: "100%",
            }}
            onChange={(e) => setApiDescription(e.target.value)}
            onFocus={(e) => (e.target.style.borderColor = "#007BFF")}
            onBlur={(e) => (e.target.style.borderColor = "#ccc")}
          />
          <br></br>
          {localStorage.getItem("CID") === "SYSTEM" && (
            <>
              <label
                htmlFor="api-description"
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginBottom: "5px",
                }}
              >
                Feature ID
              </label>
              <select
                id="feat-id"
                style={{
                  padding: "10px",
                  fontSize: "14px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  outline: "none",
                  transition: "border-color 0.3s",
                  width: "100%",
                }}
                onChange={(e) => setFeatID(e.target.value)}
                onFocus={(e) => (e.target.style.borderColor = "#007BFF")}
                onBlur={(e) => (e.target.style.borderColor = "#ccc")}
              >
                <option value="">Select a feature</option>
                <option value="ADMIN">Admin</option>
                {aFeatures.map((feature) => (
                  <option key={feature.FEAT_ID} value={feature.FEAT_ID}>
                    {feature.FEAT_DESC}
                  </option>
                ))}
              </select>
              <br />
            </>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <input
              type="checkbox"
              defaultValue={bGeoAPI}
              style={{
                height: "20px",
                width: "20px",
                marginRight: "10px",
              }}
              onChange={(event) => handleChange(event)}
            ></input>
            <label>Geo Locator API</label>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm}>Create</Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
